export enum Screen {
    AccesoConsultaFacturas = 'D125D2DD-EEC7-4E25-B5BB-0C4449B40E85',
    AccesoPlataforma = '0DF0D701-F430-4AE4-962A-6CC8EA9F78D9',
    CargaComprobantesPago = 'DD0B926C-074E-47A2-9D8C-6843B11596D3',
    ConsultaSolicitudes = '1AB9286A-41A5-49AF-A291-3B0EDBA71A4E',
    DespachoAlmacen = 'F75B0236-FE10-41CB-9A5B-A89695C6722B',
    EncuestaSatisfaccion = '9399A950-1568-4DC6-9C7B-423DFDDAA970',
    EntradaAlmacen = 'DC593099-B3D8-4444-B7D9-5D695B676A3D',
    EnvioMensaje = 'BC078397-4BA1-4956-B7CA-9D21E907FE7D',
    EstimadoDesconsolidación = '190940C9-231C-4B09-826D-770EA4904E08',
    ImpresionCotizacionAlmacenajeDesconsolidacion = '3475024D-1DB6-4009-9DDF-8E06B2AA5323',
    ImpresionFactura = 'DA31D786-6627-49FC-A929-6EF1E8EE1BD5',
    PagoFactura = 'E136686E-4F69-473E-8A14-DFF1947210FD',
    TasaCambio = '68A858BD-2247-47C7-95A1-F3A7A40D5D50',
    Tracking = '082F7265-98A4-4839-ABEA-6007153F362E',
}