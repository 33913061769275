import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { trackAndTraceOptions } from './trackAndTraceOptions';
import { TrackAndTraceService } from '../../services/track-and-trace.service';
import serviceConfig from '../../services/service-config';
import Swal from 'sweetalert2';
import { LogService } from '../../services/log/log.service';
import { LogDto } from '../../models/dto/log.dto';
import { Action } from '../../models/enums/action.enum';
import { Screen } from '../../models/enums/screen.enum';
import * as jwt_decode from 'jwt-decode';
import { select, Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'kt-locate-cargo',
  templateUrl: './locate-cargo.component.html',
  styleUrls: ['./locate-cargo.component.scss']
})
export class LocateCargoComponent implements OnInit {

  typeSelected = 'BL';
  referenceNumber = '';
  message = 'Favor indicar el número de BL, PO o Contenedor para realizar las búsqueda.';
  activeId = '';

  bls: any = [];
  blSelected : string = '';
  blData: any = {}
  blErrors: any = {}

  endpoints = {
    BL: 'traceByBL',
    PO: 'traceByPO',
    Contenedor: 'traceByContainer'
  }

  steps = [
    {
      label: 'Registrado',
      icon: '../../../assets/tracking/registro.png',
      xkey: 'registeredDate',
      date: null,
      matIcon: 'rate_review'
    },
    {
      label: 'En clasificación y digitación',
      icon: '../../../assets/tracking/clasificacion.png',
      xkey: 'typedDate',
      date: null,
      matIcon: 'receipt_long'
    },
    {
      label: 'Declarado',
      icon: '../../../assets/tracking/declarado.png',
      xkey: 'declaredDate',
      date: null,
      matIcon: 'collections_bookmark'
    },
    {
      label: 'En proceso de verificación',
      icon: '../../../assets/tracking/proceso-verificacion.png',
      xkey: 'verifiedProcess',
      date: null,
      matIcon: 'assistant'
    },
    {
      label: 'Verificado',
      icon: '../../../assets/tracking/verificacion.png',
      xkey: 'verifiedDate',
      date: null,
      matIcon: 'beenhere'
    },
    {
      label: 'Valores enviados para pago',
      icon: '../../../assets/tracking/pago.png',
      xkey: 'valuesSentForPayment',
      date: null,
      matIcon: 'payments'
    },
    {
      label: 'Liberado por aduanas',
      icon: '../../../assets/tracking/liberado-por-aduana.png',
      xkey: 'releasedDate',
      date: null,
      matIcon: 'engineering'
    },
    {
      label: 'En tránsito hacia destino final',
      icon: '../../../assets/tracking/en-transito.png',
      xkey: 'transitFinalDetinationDate',
      date: null,
      matIcon: 'directions_boat'
    },
    {
      label: 'Entregado',
      icon: '../../../assets/tracking/entregado.png',
      xkey: 'deliveryDate',
      date: null,
      matIcon: 'person_pin'
    }
  ]

  items = []

  actualStep = 0;

  locateOptions = trackAndTraceOptions;
  payload$: any;
  pageNumber : number; 
  pageSize:number = 10;
  notRows: boolean;
  page: number = 1;

  constructor(private trackAndTraceService: TrackAndTraceService, private toastr: ToastrService, private store: Store<AppState>,  private chRef: ChangeDetectorRef, private logService: LogService) {

   }

  ngOnInit() { 
    this.payload$ = this.store.pipe(select(selectAuthState));   
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.AccederPantalla,
        ScreenId : Screen.Tracking,
        Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    })    
    let cargData = serviceConfig.dummies.cargoData;
    if(cargData.useDummy){
      this.blSelected = cargData.blSelected;
      this.bls = cargData.bls;
      this.blData = cargData.blData;
      this.chRef.detectChanges();
    }
    this.loadLastBls(1, this.pageSize);
  }

  loadLastBls(pageNumber : number, pageSize:number){
    this.trackAndTraceService.getLastBLs(pageNumber, pageSize)
    .subscribe((result) => {  
      this.bls = result.response.map((item) => item.blNumber);
      this.bls.Total = result['total'];
      this.notRows = result['total'] == '0';

      this.chRef.detectChanges();
    }, 
    error => {this.notRows = true;})
  }

  changingPanel(bl){
    this.steps = this.steps.map((step) => {
      return { ...step, date: null}
    })
    this.blSelected = bl;
    if(!this.blData[bl]){
      this.blErrors[bl] = '';
      this.trackAndTraceService.getResultBy(this.endpoints.BL, bl).subscribe((result) => {        
        this.blData[bl] = result;
        this.chRef.detectChanges();
      }, (error) => {
        console.log("bl error:::", error.error)
        this.blErrors[bl] = error.error;
        this.blData[bl] = 'error'
        this.chRef.detectChanges();
      })
    }
  }

  getActualStep(bl){
    let index = 0;
 
    if(this.blData[bl]){
      for(let i in this.steps){
        let step = this.steps[i];
        this.steps[i].date = this.blData[bl][step.xkey];              
        if(step.label.toLowerCase() == this.blData[bl].declaredStatus.toLowerCase()){             
          index = parseInt(i);
          break;
        } 
      }
    }
    return index;
  }

  clearData(){
    this.bls = [];
    this.blData = {};
    this.blSelected = '';
    this.message = 'Buscando...';
  }
  
  search(){    
    if(this.typeSelected && this.referenceNumber){
      this.clearData();
      this.trackAndTraceService.getResultBy(this.endpoints[this.typeSelected], this.referenceNumber).subscribe((result) => {        
        if(Array.isArray(result)){          
          this.bls = result;
          this.activeId = '';
        } else {          
          this.bls = [result.blNumber];
          this.blData[result.blNumber] = result;
          this.blSelected = result.blNumber;
          this.activeId = 'ngb-panel-0';
        }
        this.chRef.detectChanges();
      }, (error) => {
        console.log("ERROR:::::::",error.error);
        this.message = error.error;
        this.chRef.detectChanges();
      })
    } else {
      this.toastr.info('Debe indicar el tipo y la referencia.','Aviso');
    }
    // 
  }

  onPageChange(item: number) {
    this.page = item;
    this.loadLastBls(item, this.pageSize);
  }
  get pageTotal() {
    return Math.ceil(this.bls ? this.bls.Total / 10 : 0);
  }

  get pages(): number[] {
    let pages = [];
    for (let i = 0; i < this.pageTotal; i++) {
      pages.push(i + 1);
    }
    return pages;
  }

  onDropDownPageChange(item: number){
    this.pageSize = item;
    this.loadLastBls(1, this.pageSize);
  }
}
