export class ServiceRequest{
    request_id: number;
    service_type: number;
    quotation: string; 
    bag_type: number;
    bag_qty: number;
    weight: number;
    weight_unit:string;
    bl_description: string;
    reference: string; 
    comment: string; 
    import: boolean; 
    export: boolean; 
    customs: boolean; 
    transport: boolean; 
    client_id: string;
    customer_id: string;
    created_date: Date;
    date_modified: Date;
    assigned_user: string;
    assigned_name: string;
    status: number;    
    client_name: string;
    company_name: string;
    status_description: string;    
    responsible: string;
    request_type: string;
    expediente: string;
}