import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { AppState } from '../../core/reducers/index';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  uri = serviceConfig.altBaseUrl;
  headers: HttpHeaders;

  
  constructor(private http:HttpClient, private store: Store<AppState> ) { 
    this.store.select(selectAuthState).subscribe((result) => {   
      this.headers = new HttpHeaders()
                          .append('Authorization', "Bearer " + result.authToken)  
                          .append('Content-Type', 'application/json')
                          .append('accept','*/*')
                          .append('accept','text/plain');
    });
  }

  getProductsByRegimen (regimen:string):Observable<any> {
    let url = this.uri + serviceConfig.endPoints.wareHouseProducts;    
    return this
      .http
      .get<any>(`${url}?regimen=${regimen}`,
       { headers: this.headers });

  }

  getStockssByRegimen (regimen:string):Observable<any> {
    let url = this.uri + serviceConfig.endPoints.wareHouseStocks;    
    return this
      .http
      .get<any>(`${url}?regimen=${regimen}`,
       { headers: this.headers });

  }
}
