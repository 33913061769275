export class BillVoucherModel{
    id:number;
    billId: string;
    recordId: string;
    company: string;
    bill_date: string;
    ncf: string;
    user_id: string;
    created_date: string;
    Status: string;
    assigned_user: string;
    client_name:string;
    customer_id: string;
}