import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  constructor() { }

  @Input() page: number;
  @Input() pageSize: number;
  @Input() totalItem: number;
  @Input() showDropDownPaginator:boolean;
  @Output() onSelectPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectDropDownPage: EventEmitter<any> = new EventEmitter<any>();


  get pageTotal() {    
    return Math.ceil(this.totalItem ? this.totalItem / this.pageSize : 0);
  }

  get pages():number[] {    
    let pages = [];
    for(let i = 0; i < this.pageTotal; i++){      
      pages.push(i + 1);
    }
    return pages;
  }

  selectPage(item: number){
    this.page = item;
    this.onSelectPage.emit(item);
  }

  canShowPage(index: number, pageTotal: number, page: number){    
    return (index >= (page - 3) && index <= (page + 1))
  }

  showMaxSkip(index: number, pageTotal: number, page: number){
    let result = false;

    if(pageTotal > 5){      
      return (index >= (page - 4) && index <= (page + 2))
    }

    return result;
  }

  dropDownPageChange(item){
    this.pageSize = item.target.value;
    this.onSelectDropDownPage.emit(this.pageSize);
  }

  ngOnInit() {
  }

}
