import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../../../core/reducers';
import { Logout, currentUser, User, AuthService } from '../../../core/auth';
import { selectAuthState } from '../../../core/auth/_selectors/auth.selectors';
import * as jwt_decode from "jwt-decode";
import { DOCUMENT } from '@angular/common';
import { SidebarService } from '../../../services/sidebar.service';
import { LogService } from '../../../services/log/log.service';
import { LogDto } from '../../../models/dto/log.dto';
import { Action } from '../../../models/enums/action.enum';
import { Screen } from '../../../models/enums/screen.enum';
var md5 = require('md5');

@Component({
  selector: 'kt-mdheader',
  templateUrl: './mdheader.component.html',
  styleUrls: ['./mdheader.component.scss']
})
export class MdheaderComponent implements OnInit {
  user$: Observable<User>;

  username: string = '';
  company: string = '';
  email = '';
  emailHash = '';
  elem: any; isFullScreen: boolean;  
  token : any ;
  payload$: Observable<any>;
  //constructor(private authService: AuthService) { }
  constructor(private store: Store<AppState>,
              private authService: AuthService, 
              @Inject(DOCUMENT) private document: any,
              private logService: LogService,
              private sidebarService: SidebarService) {
  
   }

  ngOnInit() {
    this.chkScreenMode();
    this.elem = document.documentElement;
    this.user$ = this.store.pipe(select(currentUser));
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.user$.subscribe((user) => {      
      this.email = user.email;
      this.emailHash = user.email ? md5(user.email) : '';
      this.username = user.fullname;
      this.company = user.companyName;              
    })
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.AccederPantalla,
        ScreenId : Screen.AccesoPlataforma,
        Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    }) 
    
  }

  toogleMenu(){
    this.sidebarService.toggleSidebarVisibility();
  }

  logout(){
     this.store.dispatch(new Logout());
  }

  @HostListener('document:fullscreenchange', ['$event'])
  @HostListener('document:webkitfullscreenchange', ['$event'])
  @HostListener('document:mozfullscreenchange', ['$event'])
  @HostListener('document:MSFullscreenChange', ['$event'])

    fullscreenmodes(event){
      this.chkScreenMode();
    }
  chkScreenMode(){
      if(document.fullscreenElement){
        //fullscreen
        this.isFullScreen = true;
      }else{
        //not in full screen
        this.isFullScreen = false;
      }
    }

    toggleFullScreen(){
      this.chkScreenMode()
      if(this.isFullScreen){
        this.closeFullscreen()    ;
      }else{
        this.openFullscreen();
      }
    }

    openFullscreen() {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    }
    closeFullscreen() {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
}
