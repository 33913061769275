// NGRX
import { createSelector } from '@ngrx/store';
export const selectAuthState = state => state.auth;
export const selectUserState = state => state.auth.user;
export const isLoggedIn = createSelector(
  selectAuthState,
  auth => auth.loggedIn
);

export const isLoggingIn = createSelector(
  selectAuthState,
  auth => auth.loggingIn
);
export const isLoggedOut = createSelector(isLoggedIn, loggedIn => !loggedIn);

export const currentAuthToken = createSelector(
  selectAuthState,
  auth => auth.authToken
);

export const currentIdToken = createSelector(
  selectAuthState,
  auth => auth.idToken
);

export const isUserLoaded = createSelector(
  selectAuthState,
  auth => auth.isUserLoaded
);

export const currentUser = createSelector(selectAuthState, auth => auth.user);

export const currentUserRoleIds = createSelector(currentUser, user => {
  if (!user) {
    return [];
  }
  return user.roles;
});

export const currentUserPermissions = createSelector(selectAuthState, auth => {
  return auth.user.permissions;
});
