import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ITransportEquipment } from '../../../../models/transport-equipment';
import { GenericRequestsService } from '../../../../services/generic-requests.service';
import serviceConfig from '../../../../services/service-config';


@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss']
})
export class EquipmentsComponent implements OnInit {

  @Output() transportEquipmentEmitter: EventEmitter<ITransportEquipment[]> = new EventEmitter<ITransportEquipment[]>();

  @Input() equipments: ITransportEquipment[] = []
  @Input() disabledAdd: Boolean = false
  selectedEquipment: ITransportEquipment;
  selectedIndex: number = 0;

  constructor(private modalService: NgbModal) { }

  ngOnInit() {   
    console.log(this.equipments)
  }

  @ViewChild('modal', { static: true }) mapModal: TemplateRef<any>;

  add(){
    this.selectedEquipment = new ITransportEquipment();
    this.modalService.open(this.mapModal, {size: 'lg'});
  }

  addEquipment(data){    
    this.modalService.dismissAll();
    this.equipments.push({...data});
    this.transportEquipmentEmitter.emit(this.equipments);
  }

  editEquipment(equipment: ITransportEquipment, index: number){
    this.selectedEquipment = equipment;
    this.selectedIndex = index;
    this.modalService.open(this.mapModal, {size: 'lg'});
  }

  deleteEquipment(index: number){
    this.equipments.splice(index, 1);
  }

}
