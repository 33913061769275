import { Injectable } from '@angular/core';
import 'sweetalert2/src/sweetalert2.scss'

import Swal from 'sweetalert2'

@Injectable()
export class AlertService {
    swal: any
    constructor() {
        this.swal = Swal; 
     }
    error(message: any, title?: string): Promise<any> {        
        return this.swal.fire({
            title: title || 'Error',
            text: message.error || message,
            confirmButtonColor: '#EF5350',
            icon: 'error'            
        });
    }
    info(message: string, title?: string): Promise<any> {
        return this.swal.fire({
            title: title || 'Info',
            text: message,
            confirmButtonColor: '#2196F3',
            icon: 'info',            
        });
    }
    warning(message: string, title?: string): Promise<any> {
        return this.swal.fire({
            title: title || 'Peligro',
            text: message,
            confirmButtonColor: '#FF7043',
            icon: 'warning',            
        });
    }
    success(message: string, title?: string): Promise<any> {        
        return this.swal.fire({
            title: title || 'Realizado',
            text: message,
            confirmButtonColor: '#66BB6A',
            icon: 'success',            
        });
    }
    question(message: string, title?: string): Promise<any> {
        return this.swal.fire(
            {
                title: title || '¿Está seguro?',
                text: message,                
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#EF5350',
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                showLoaderOnConfirm: true,
                closeOnConfirm: false,
                closeOnCancel: true
            })
    }
}
