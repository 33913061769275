import { environment } from '../../environments/environment';

const DevUrl = {
  baseUrl: 'http://10.228.220.15:8088/api/gateway/trackandtrace',
  altBaseUrl: 'http://10.228.220.15:8088/api',
  filesUrl: 'http://10.228.220.15:8088/api/storage/file',
  trackAndTraceBaseUrl: 'http://10.228.220.13:8080/api/tracking',
  customsFilesUrl: 'http://10.228.30.241:8080',
  manifestBaseUrl:'http://10.228.220.15:8088/api/manifest-services', 
	azualApprovedUrl: "http://10.228.220.15:8088/transferlist?ApprovedUrl",
	azualDeclinedUrl: "http://10.228.220.15:8088/transferlist?DeclinedUrl",
	azualCancelUrl: "http://10.228.220.15:8088/transferlist?CancelUrl",
}

const serviceConfig = {
  baseUrl: environment.Endpoint.baseUrl.indexOf('#{') > -1 ? DevUrl.baseUrl : environment.Endpoint.baseUrl,
  altBaseUrl: environment.Endpoint.altBaseUrl.indexOf('#{') > -1 ? DevUrl.altBaseUrl : environment.Endpoint.altBaseUrl,
  filesUrl: environment.Endpoint.filesUrl.indexOf('#{') > -1 ? DevUrl.filesUrl : environment.Endpoint.filesUrl,
  trackAndTraceBaseUrl: environment.Endpoint.trackAndTraceBaseUrl.indexOf('#{') > -1 ? DevUrl.trackAndTraceBaseUrl : environment.Endpoint.trackAndTraceBaseUrl,
  customsFilesUrl: environment.Endpoint.customsFilesUrl.indexOf('#{') > -1 ? DevUrl.customsFilesUrl : environment.Endpoint.customsFilesUrl,
  manifestBaseUrl: environment.Endpoint.manifestBaseUrl.indexOf('#{') > -1 ? DevUrl.manifestBaseUrl : environment.Endpoint.manifestBaseUrl,
  azualApprovedUrl: environment.Endpoint.azualApprovedUrl.indexOf('#{') > -1 ? DevUrl.azualApprovedUrl : environment.Endpoint.azualApprovedUrl,
  azualDeclinedUrl: environment.Endpoint.azualDeclinedUrl.indexOf('#{') > -1 ? DevUrl.azualDeclinedUrl : environment.Endpoint.azualDeclinedUrl,
  azualCancelUrl: environment.Endpoint.azualCancelUrl.indexOf('#{') > -1 ? DevUrl.azualCancelUrl : environment.Endpoint.azualCancelUrl,

  authKey: 'sXtd0amINK017ZGEaaE6j8k9RTzF62Tw',
  endPoints: {
    log: '/general-information/audit',
    booking: '/Bookings',
    customs: '/customManagement',
    outboxMessage: '/OutboxMessage',
    contact: '/Contacts',
    request: '/RequestService',
    transport: '/TransportService',
    voucher: '/TransferVoucher',
    billVoucher: '/BillVoucher',
    customTypesByType: '/CustomsManagementType',
    packingTypes: '/Packingtype',
    ports: '/general-information/port',
    cargotype: '/cargotype',
    EquipmentType: '/general-information/transportequipment',
    wareHouseProducts: '/logistic-warehouse-query/Products/GetProductsByClient',
    wareHouseStocks: '/logistic-warehouse-query/Stock/GetStockByIdClients',
    responsibleCustomer: '/responsible-management/ResponsibleCustomer',
    responsible: '/account-management/EmployeesAccount/GetAllCordinator',
    responsibleLine: '/responsible-management/ResponsibleRequestType/ByLineId/',
    responsibleRequestTypeCustomer:
      '/responsible-management/ResponsibleCustomer/ResponsibleRequestTypeCustomer/',
    customers: '/account-management/Client',
    agents: '/logistic-warehouse-query/Agents/GetByIdClients',
    changeStatus: '/Miscelaneos',
    orderEntry: '/OrderEntry',
    orderDetail: '/OrderDetail',
    referenceDetail: '/ReferenceDetail',
    receptionReference: '/ReceptionReference',
    wareHouseReference: '/logistic-warehouse/ReferenceReception',
    wareHouseOrder: '/logistic-warehouse/Purchase',
    trackBL: '/BL',
    pendingInvoice: '/invoice-api/invoices',
    downloadInvoiceId: '/mardomgo-reports/InvoiceReport',
    downloadInvoiceFF: '/mardomgo-reports/InvoiceReport/FreighForward',
    downloadInvoiceDynamic: '/mardomgo-reports/InvoiceReport/Dynamic',
    warehouseTHByTally: '/desconsolidation-warehouse/BillOfLading/GetByTally',
    warehouseTHAll: '/desconsolidation-warehouse/BillOfLading/',
    rates: '/general-information/dollarrate',
    warehouseTHByBL: '/desconsolidation-warehouse/BillOfLading/GetByNumber',
    warehouseTHQuotation: '/desconsolidation-warehouse/Quotation',
    downloadInvoiceStorageQuote:
      '/mardomgo-reports/InvoiceReport/ReportStorageQuoteTH',
    azureUpload: '/upload',
    azureDownload: '',
    serviceFiles: '/ServiceFiles',
    traceByPO: '/purchaseorder',
    traceByBL: '/windip/getbybl',
    traceByContainer: '/container',
    BillVoucher: '/BillVoucher',
    ships: '/general-information/ship',
    employeesAccount: '/account-management/EmployeesAccount',
    trackingLasts: '/windip/getbycustomer',
    fileNumber: '/expediente',
    salesexecutive: '/general-information/SalesExecutive',
    tmsServiceType:'/transport-service/TMSTransportRequest/services',
    tmsShipLine:'/transport-service/TMSTransportRequest/shiplines',    
    transportRequestSave:'/transport-service/TransportRequest',
    tmsEquipment: '/transport-service/TMSTransportRequest/equipments',
    tmsEquipmentType: '/transport-service/TMSTransportRequest/equipmenttypes',
    tmsLocations: '/transport-service/TMSTransportRequest/freightlocations', 
    ship:'/Manifest/GetShip?name=',
		port:'/Manifest/GetPorts?name=',
		containerType:'/Manifest/GetContainerType',
		loadTypeandClient:'/Manifest/GetTypes',
		typeofMerchandise:'/Manifest/GetMerchandiseType',
		agencies:'/Manifest/GetAgencies',
		packingType:'/Manifest/GetPackagesType',
		city:'/Manifest/GetCities?name=',
		dangerType:'/Manifest/GetDangerType',
		saveParseXML:'/Manifest/ParseXmlManifest',
		uploadmanifestDoc:'/Files/UploadmanifestDoc',
		saveManifest:'/Manifest/SaveManifiest',
		updateManifest:'/Manifest/UpdateManifiest',
		updateImpediment:'/bol/UpdateDmImpediment',
		serchManifest:'/Manifest/GetManifestList',
		getDocument:'/Files/GetByManifest?manifest=',
		getInvoice:'/Bill/GetQuotation?Id=',
		downloadDocument:'/Files/Download?id=',
		manifetDetailsById:'/Manifest/GetManifestById?manifestId=',
		verifyRNC:'/Manifest/GetClientByRnc',
		paymentRequest:'/Payment/GetSignedPaymentRequest',
		getAuthHash:'/Payment/GetAuthHash',
		getAzualPaymentResponse:'/Payment/AzulPaymentSuccess',
		calculateCIF:'/Bill/GetCalculateCIF',	
		getHistoryDetailsByTallyNo	:'/BOL/GetHistoryDetailsByTallyNo?TallyNo=',
	  getAllBlDetailsList:'/BOL/GetAllBillDetails',
		changeBlStatus:'/BOL/AddHistoryStatus',
    menifestWis:'/Manifest/ManifestWis?manifestNo='
		   
  },
  requesttypes: {
    transport: '90354f16-6872-48a7-3144-08d83a029f26',
    import: '785c41a7-5706-4743-3147-08d83a029f26',
    customs: '6985d287-8d9e-45b0-3145-08d83a029f26',
    voucher: '12b4d4f4-e552-4984-94dd-17a286c2024e'
  },
  lines: {
    geest: 'E6687B8F-3B18-455F-3F05-08D83A0273F8',
    baltic: '07696A60-C7A6-4B5F-3F07-08D83A0273F8',
    cosiarma: '1F0FC5FF-A94A-4643-3F06-08D83A0273F8',
    freightForward: '4F6E46D3-F526-486D-3F08-08D83A0273F8'
  },
  dummies: {
    cargoData: {
      useDummy: false,
      bls: ['S055676200650065', 'ECN0177413'],
      blSelected: 'S055676200650065',
      blData: {
        S055676200650065: {
          registeredDate: '2020-07-03T15:20:10',
          typedDate: '2020-07-07T14:59:19',
          declaredDate: '2020-07-09T16:44:15',
          verifiedDate: '2020-07-14T16:44:15',
          verifiedProcess: '2020-07-14T16:44:15',
          valuesSentForPayment: '2020-07-23T15:07:03',
          releasedDate: '2020-07-14T16:44:15',
          transitFinalDetinationDate: '2020-07-14T11:36:19',
          deliveryDate: '2020-07-14T16:09:12',
          declaredStatusDate: '2020-07-14T16:44:15',
          declaredStatus: 'Liberado por Aduanas',
          declaredSubStatus: 'En proceso de exoneracion',
          declaredDateSubStatus: '2020-07-28T16:28:25',
          blNumber: 'S055676200650065',
          documentNumber: '101886714',
          containerList: [
            {
              containerNumber: 'FCIU9430640',
              returnDate: '2020-07-14T11:36:19',
              retirementDate: '2020-07-14T08:00:00'
            },
            {
              containerNumber: 'SMLU7906632',
              returnDate: '2020-07-15T09:04:18',
              retirementDate: '2020-07-15T08:00:00'
            },
            {
              containerNumber: 'SMLU6415845',
              returnDate: '2020-07-15T09:04:18',
              retirementDate: '2020-07-15T08:00:00'
            },
            {
              containerNumber: 'SMLU6415845',
              returnDate: '2020-07-15T12:56:51',
              retirementDate: '2020-07-16T08:00:00'
            },
            {
              containerNumber: 'SMLU7906632',
              returnDate: '2020-07-15T12:56:51',
              retirementDate: '2020-07-16T08:00:00'
            },
            {
              containerNumber: 'FCIU9430640',
              returnDate: '2020-07-14T11:49:31',
              retirementDate: '2020-07-15T08:00:00'
            }
          ]
        },
        ECN0177413: {
          registeredDate: '2020-06-30T12:32:13',
          typedDate: '2020-07-01T10:21:07',
          declaredDate: '2020-07-28T10:29:06',
          verifiedDate: null,
          verifiedProcess: null,
          valuesSentForPayment: null,
          releasedDate: null,
          transitFinalDetinationDate: null,
          deliveryDate: null,
          declaredStatusDate: '2020-07-28T10:29:06',
          declaredStatus: 'Declarado',
          declaredSubStatus: null,
          declaredDateSubStatus: '2020-07-28T10:29:10',
          blNumber: 'ECN0177413',
          documentNumber: '101886714',
          containerList: []
        }
      }
    }
  }
};
export default serviceConfig;
