import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {RatesService } from '../../../services/miscelaneos/rates.service';
import { Observable } from 'rxjs';
import { LogService } from '../../../services/log/log.service';
import { LogDto } from '../../../models/dto/log.dto';
import { Screen } from '../../../models/enums/screen.enum';
import { Action } from '../../../models/enums/action.enum';
import { select, Store } from '@ngrx/store';
import { selectAuthState } from '../../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../../core/reducers';
import * as jwt_decode from 'jwt-decode';


@Component({
  selector: 'kt-mdfooter',
  templateUrl: './mdfooter.component.html',
  styleUrls: ['./mdfooter.component.scss']
})
export class MdfooterComponent implements OnInit {
  payload$: any;

  constructor(private rateService: RatesService, private store: Store<AppState>, private chRef: ChangeDetectorRef, private logService: LogService) { }
  dollarPurchase
  dollarSales

  ngOnInit() {
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.rateService.getRates().subscribe((result: any) => 
      {

        this.dollarPurchase=result.dollarPurchase;
        this.dollarSales=result.dollarSales;
        this.chRef.detectChanges();
      });
  }

  saveLog(){
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.AccederPantalla,
        ScreenId : Screen.TasaCambio,
        Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    })
  }
}
