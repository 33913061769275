import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";

import {CustomsModel} from '../../models/customs'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { selectAuthState } from '../../../app/core/auth/_selectors/auth.selectors';

@Injectable({
  providedIn: 'root'
})
export class CustomsService {

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.customs;
  headers = new HttpHeaders()
  altHeaders: HttpHeaders;

  constructor(private http:HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe((result) => { 
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);
      this.altHeaders =new HttpHeaders()
                      .append('Authorization', "Bearer "+result.authToken)  
                      .append('accept','*/*')
                      .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                      .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                      .append('accept','text/plain');
    })
  }

  addCustom(custom: CustomsModel){
    
    this.http.post(`${this.uri}`, custom, { headers: this.headers }).subscribe(res => console.log('Done'));;
  }

  updateCustom(id:number ,custom: CustomsModel){
    this.http.put(`${this.uri}/${id}`, custom, { headers: this.headers }).subscribe(res => console.log('Done'));
  }

  getCustom(requestId:number):Observable<any> {
    return this
      .http
      .get<any>(`${this.uri}?RequestId=${requestId}`,
       { headers: this.headers });

  }

}
