import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '../../../../services/booking/request.service';
import { ServicefileService } from '../../../../services/files/servicefile.service';
import { ResponsibleService } from '../../../../services/responsiblemanagement/responsible.service';
import { ServiceRequest } from '../../../../models/service-request';
import { Subject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { formatDate } from '@angular/common';
import { AppState } from '../../../../core/reducers/index';
import { GenericRequestsService } from '../../../../services/generic-requests.service';
import { AzurefileService } from '../../../../services/files/azurefile.service';
import serviceConfig from '../../../../services/service-config';
import { ChangeStatusModel } from '../../../../models/changestatus';
import { ServiceFileModel } from '../../../../models/servicefile';
import { ClientService } from '../../../../services/accountmanagement/client.service';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import {
  currentUserRoleIds,
  selectAuthState
} from '../../../../core/auth/_selectors/auth.selectors';
import { selectAllPermissions } from '../../../../core/auth/_selectors/permission.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseResult } from '../../../../models/response-result.model';
import { ThrowStmt } from '@angular/compiler';
import * as forge from 'node-forge';
import { LogService } from '../../../../services/log/log.service';
import { LogDto } from '../../../../models/dto/log.dto';
import { Action } from '../../../../models/enums/action.enum';
import { Screen } from '../../../../models/enums/screen.enum';
import * as ServiceRequestActions from '../../store/actions/service-request.actions';
import { getSearchForm } from '../../store/selectors/service-request.selector';
import { SearchForm } from '../../models/searchFormDto';

@Component({
  selector: 'kt-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('detailModal', { static: true }) detailModal: TemplateRef<any>;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtInstance: Promise<DataTables.Api>;
  privateKey: string = `-----BEGIN RSA PRIVATE KEY-----
	MIIEpAIBAAKCAQEAskgPKBcNpz71mi4NSYa5mazJrO0WZim7T2yy7qPxk2NqQE7O
	mWWakLJcaeUYnI0kO3yC57vck66RPCjKxWuWSGZ7dHXe0bWb5IXjcT4mNdnUIalR
	+lV8czsoH/wDUvkQdG1SJ+IxzW64WvoaCRZ+/4wBF2cSUh9oLwGEXiodUJ9oJXFZ
	VPKGCEjPcBI0vC2ADBRmVQ1sKsZg8zbHN+guU9rPLFzN4YNrCnEsSezVw/W1FKVS
	8J/Xx4HSSg7AyVwniz8eHi0e3a8VzFg+H09I5wK+w39sjDYfAdnJUkr6PjtSbN4/
	Sg/NMkKB2Ngn8oj7LCfe/7RNqIdiS+dQuSFgeQIDAQABAoIBAQCtAyEyVqz5UTAn
	n+llIUmcyQIuvyatnnIYcfRzgsY9soy6O8BjPSbypSTp31ozEwXGdN7U5Ir7Br4L
	E8s0K2R8fnfwXTMPcJ8I9qXyj82lFO+4ewkDV1KewRn/cCIJs4s0caL48ZpDMt/V
	6kC58QruFIA6pdwxtij09RPwDg7E3U1MrL9PqgGXXQcAwtboP7gHs2ac+0b6CwV0
	Cyev09Xpni3ID0iKX8yKsxi6wOh+2O4Mz8B3R2gIXq7Gzw8oeho0nSBD19B7fWs+
	w4vFlHIl57zbeISQK10cvvJ8BB4byy/bn5V2O2lESgU10d48csD5IUcjdV4/yuFx
	vI8S9vfBAoGBAOqbw03WXnhZQu8O7Gos8P7lrBi3eYdF45hHAfWbK5ub6O7fC0kQ
	FYZqO0AR7oEAzuApWP0uGxQFALZ0QPyFZbpnKinE9T9a4M5AaP1Ex8WmeWbto96b
	md6jVRXKXz24x5GDk+UdF7PJF91DufpUB5ZaO/AXpB9/3R9BDu7JHTjzAoGBAMKJ
	grfZmyH3B9Cslnb8HUSlbXZQ/tnLoc3DpXnaENpYKWz/FMjqxTHuVRRqH86tYL33
	/ow3UCaN3EYgIguoVC+DTbzk3/I9IXXeDXrocH1qCUy+x6dohzPoKZMoILLs+VeF
	eAJfIptgVIfcD5teKdEY4lYPbV0m47Q2ET8pgNvjAoGADWgDPBJw6Y2oPoLqzZJt
	3xL+x5bMcgdzXwNHwGIylrzNDcFfIrixkjHF2v5rU6HZMIQMWsNktrsmdEamZAe3
	bXRxu1tO7GTkDcUuerKQe+HsdA5GW/UmUMuilBExahSCCvWq4N/Nt+9Y1sMFAKIG
	oPfdDvY64bXry9pq4QhLDDMCgYAGs9ovH24641n/lJKyksknXlPBKdU+B8gRYNZI
	tnyyKSt0N+2tZnlY2Q9RXr6tzkdxBCRfDGDUkzqtp9nEcQ5FfM6m3XPh0r1eZEIu
	o+eMSqT7Ye9bEzzlSvBzw4Qs5gFJRK331vNISy9eZvSas8nZDHRv5qQr+3UI9nvt
	oy5vZQKBgQCK5z2IeL1NFd5OuJaR07Y+xwO7IOiZ+nn+fXWYkZ4bD08Dg0ACnd/w
	OcS+2fU2/IIuXnhT/f5M5r0AJI1lG/V8TBo4gxN2dm7yBLgczbK3u+tex+pEnfEZ
	qxAE0159q3iGvU5nmtX6VC0Z/IJIUCVgiEMwoKct0T5eOV2iQf5PaQ==
	-----END RSA PRIVATE KEY-----`;

  requests: ResponseResult<ServiceRequest> = new ResponseResult<ServiceRequest>();
  uploadedFiles: ServiceFileModel[] = [];
  formData = {};
  searchForm: FormGroup;
  statusForm: FormGroup;

  page: number = 1;
  loading: boolean = false;

  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  blDTOptions: any = {};
  changeModel: ChangeStatusModel;
  executiveUsers = [];
  customersSl = [];
  responsibleId = '';
  responsibleName = '';
  customerId = '0';
  adminFilter = false;
  canSaveResponsible = false;

  singleSelectOptions = {
    width: '100%',
    multiple: false,
    placeholder: 'Seleccionar cliente'
  };

  executiveUserSelected: any;
  clientFilter: any;

  requestSelected: any;
  permissions: any[];
  role: any;
  payload$: Observable<any>;

  constructor(
    private requestService: RequestService,
    private responsibleService: ResponsibleService,
    private clientService: ClientService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private genericRequestService: GenericRequestsService,
    private sFileService: ServicefileService,
    private azFileService: AzurefileService,
    private chRef: ChangeDetectorRef,
    private logService: LogService,
    private router: Router,
    private currentRouter: ActivatedRoute
  ) {
    this.store.select(selectAllPermissions).subscribe(res => {
      this.permissions = res;
    });
  }

  get pageTotal() {
    return Math.ceil(this.requests ? this.requests.Count / 10 : 0);
  }

  get pages(): number[] {
    let pages = [];
    for (let i = 0; i < this.pageTotal; i++) {
      pages.push(i + 1);
    }
    return pages;
  }

  showModal(modal) {
    this.modalService.open(modal, { size: 'lg' });
  }

  selectedItem(item) {
    if (item.data.length > 0) {
      if (this.responsibleId != item.value) this.canSaveResponsible = true;

      this.responsibleName = item.data[0].text;
      this.responsibleId = item.value;
    }
  }

  selectStatus(item) {
    if (this.statusForm.value.statusType != item.value)
      this.canSaveResponsible = true;
  }

  selectedItemCustomer(item) {
    this.customerId = item.value;
    this.clientFilter = item.value;
    this.searchForm.get('clientFilter').setValue(item.value); 
  }

  async ngOnInit() {
    this.payload$ = this.store.pipe(select(selectAuthState));    

    this.blDTOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      filter: false,
      ordering: false,
      language: {
        lengthMenu: 'Mostrando _MENU_ registros por página',
        zeroRecords: '...',
        info: 'Mostrando página _PAGE_ de _PAGES_',
        infoEmpty: '..',
        infoFiltered: '(Filtrado de _MAX_ registros)',
        paginate: {
          previous: 'Anterior',
          next: 'Siguiente',
          last: 'Ultima',
          first: 'Primera'
        }
      }
    };

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: false,
      language: {
        lengthMenu: 'Mostrando _MENU_ registros por página',
        zeroRecords: 'No se encontraron registros',
        info: 'Mostrando página _PAGE_ de _PAGES_',
        infoEmpty: 'No se encontraron registros',
        infoFiltered: '(Filtrado de _MAX_ registros)',
        search: 'Filtrar solicitudes:',
        paginate: {
          previous: 'Anterior',
          next: 'Siguiente',
          last: 'Ultima',
          first: 'Primera'
        }
      }
    };

    this.statusForm = this.fb.group({
      statusType: [1],
      comment: ''
    });

    this.searchForm = this.fb.group({
      statusTypeFilter: [0],
      dateFrom: '',
      dateTo: '',
      requestId: '',
      clientFilter: [0],
      reference: '0'
    });

    this.checkUserRole();
    this.loadExecutiveUsers();
    await this.loadCustomers();
    
    if(history.state.loadStore){
      this.store.select(getSearchForm).subscribe(
        res => {
          this.setInitSearchFormState(res);        
        }
      )
    }

    this.searchRequests();

    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken);
      this.logService.postWithPayload(
        <LogDto>{
          ActionId: Action.AccederPantalla,
          ScreenId: Screen.ConsultaSolicitudes,
          Json: '',
          UserName: res.user.fullname,
          Email: res.user.email,
          ClientId:
            tokenData['https://mardom.com/clientId'] == ''
              ? null
              : tokenData['https://mardom.com/clientId'],
          RoleName:
            tokenData[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ][0]
        },
        res.authToken
      );
    });
  }

  selectRequest(request) {
    this.requestSelected = request;
    this.canSaveResponsible = false;
    this.statusForm = this.fb.group({
      statusType: [request.status],
      comment: ''
    });

    this.executiveUserSelected = request.assigned_user;
    this.responsibleId = request.assigned_user;
  }

  setSearchFormState() {    
    const form: SearchForm = { 
      statusTypeFilter: this.searchForm.value.statusTypeFilter,
      dateFrom: this.searchForm.value.dateFrom,
      dateTo: this.searchForm.value.dateTo,
      requestId: this.searchForm.value.requestId,
      clientFilter: this.searchForm.value.clientFilter,
      reference: this.searchForm.value.reference,
      page: this.page
    }
    this.store.dispatch(
      ServiceRequestActions.setSearchForm({searchForm: form})
    );
  }

  setInitSearchFormState(res: SearchForm) {
    this.searchForm.get('statusTypeFilter').setValue(res.statusTypeFilter);
    this.searchForm.get('dateFrom').setValue(res.dateFrom);
    this.searchForm.get('dateTo').setValue(res.dateTo);
    this.searchForm.get('requestId').setValue(res.requestId);
    this.searchForm.get('clientFilter').setValue(res.clientFilter);
    this.clientFilter = res.clientFilter;
    this.searchForm.get('reference').setValue(res.reference);    
    this.page = res.page;  
  }

  getUploadedDocuments(request) {
    this.sFileService
      .getServiceFiles('request', request.request_id)
      .subscribe(result => {
        this.uploadedFiles = result;
        //this.rerender();
        this.chRef.detectChanges();
      });
  }

  downloadDocument(sfile) {
    this.azFileService.downloadFile(sfile.filename).subscribe(response => {
      const url = window.URL.createObjectURL(response);
      var fileLink = document.createElement('a');
      fileLink.href = url;
      fileLink.download = sfile.file_type;
      fileLink.click();

      //window.open(url);
    }),
      error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }

  showSuccess(message) {
    this.toastr.success(message, 'Confirmación.');
  }
  showError(error, title) {
    this.toastr.error(error, title);
  }

  saveResponsibleStatus() {
    this.changeModel = new ChangeStatusModel();
    this.changeModel.requestId = this.requestSelected.request_id;
    this.changeModel.message = this.statusForm.value.comment;
    this.changeModel.estatusId = this.statusForm.value.statusType;
    this.changeModel.userId = this.responsibleId;
    this.changeModel.resposibleName = this.responsibleName;
    this.responsibleService.changeStatus(this.changeModel).subscribe(
      data => {
        this.showSuccess('Información actualizada satisfactoriamente.');
        this.statusForm = this.fb.group({
          statusType: [1],
          comment: ''
        });

        this.searchRequests();
      },
      error => {
        switch (error.status) {
          case 400:
            return this.showError(
              error.error,
              'Error actualizando solicitud'
            );
          case 500:
            return this.showError(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com',
              'Error actualizando solicitud'
            );
          case 0:
            return this.showError(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com',
              'Error actualizando solicitud'
            );
          case undefined:
            return this.showError(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com',
              'Error actualizando solicitud'
            );
        }
      }
    );
  }

  loadExecutiveUsers() {
    this.responsibleService.getResponsibles().subscribe(
      (result: any) => {
        this.executiveUsers = result.map(item => {
          var rsa = forge.pki.privateKeyFromPem(this.privateKey);
          var idByte = forge.util.decode64(item.id);
          var id = rsa.decrypt(idByte);
          //return { id: item.codigo, text: item.nombre}
          return { id: id.replace('auth0|', ''), text: item.name };
        });

        //this.executiveUsers = [{id:'0',text:'--Seleccione Responsable--'}].concat(execU);
        this.executiveUsers.splice(0, 0, {
          id: '',
          text: 'Seleccionar responsable'
        });

        this.chRef.detectChanges();
      },
      error => {
        switch (error.status) {
          case 401:
            this.toastr.info(
              'No tiene los permisos para realizar esta acción.',
              'Aviso.'
            );
            break;
          case 500:
            this.toastr.error(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
            );
            break;
          case 403:
            this.toastr.info(
              'No tiene los permisos para realizar esta acción.',
              'Aviso.'
            );
            break;
          case 0:
            this.toastr.error(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
            );
            break;
          case undefined:
            this.toastr.error(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
            );
            break;
          default:
            this.toastr.error(
              'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
            );
            break;
        }
        setTimeout(() => {
          this.router.navigateByUrl('/');
        }, 3000);
      }
    );
  }

  async loadCustomers() {
    await this.clientService.getCustomers().toPromise()
    .then((result: any) => {      
        this.customersSl = result.map(item => {
          var rsa = forge.pki.privateKeyFromPem(this.privateKey);
          var idByte = forge.util.decode64(item.id);
          var id = rsa.decrypt(idByte);
          var nameByte = forge.util.decode64(item.name);
          var name = rsa.decrypt(nameByte);
          return { id: id, text: name };
        });

        this.customersSl.splice(0, 0, { id: '', text: 'Seleccionar cliente' });

        this.chRef.detectChanges();
      }).catch(error => {
        console.log(error);
      });
  }

  changeDate() {
    let fromDate = new Date(this.searchForm.value.dateFrom);
    let toDate = new Date(this.searchForm.value.dateTo);

    if (fromDate > toDate) this.searchForm.get('dateTo').setValue('');
  }
  clearFields() {
    this.clientFilter = 0;
    this.searchForm.get('statusTypeFilter').setValue([0]);
    this.searchForm.get('dateFrom').setValue('');
    this.searchForm.get('dateTo').setValue('');
    this.searchForm.get('requestId').setValue('');
    this.searchForm.get('clientFilter').setValue([0]);    
    this.clientFilter = 0;    
    this.searchForm.get('reference').setValue(0);      
    this.page = 1;
    this.searchRequests();
  }

  checkUserRole() {
    this.store.select(selectAuthState).subscribe(result => {
      var decoded = jwt_decode(result.authToken);
      //real userid.
      var currentRoles =
        decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      this.role = currentRoles;
      var internal = currentRoles.indexOf('Client');

      if (!(internal > -1)) {
        this.adminFilter = true;
      } else {
        this.adminFilter = false;
      }
    });
  }

  onPageChange(item: number) {
    this.page = item;
    this.setSearchFormState()
    this.searchRequests();
    this.chRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  onConsult(){
    this.setSearchFormState();
    this.searchRequests();
  }

  searchRequests() {
    let coordinatorFilter = requestServiceType.All;
    if (this.role.indexOf('customs') > -1) {
      coordinatorFilter = requestServiceType.Customs;
    }

    if (this.role.indexOf('transport') > -1) {
      coordinatorFilter = requestServiceType.Transport;
    }    
    this.requestService
      .getRequestServiceList(
        this.searchForm.value.requestId == ''
          ? 0
          : this.searchForm.value.requestId,
        this.searchForm.value.dateFrom,
        this.searchForm.value.dateTo,
        this.searchForm.value.statusTypeFilter,
        this.customerId == '' ? '0' : this.customerId,
        this.searchForm.value.reference == ''
          ? 0
          : this.searchForm.value.reference,
        this.adminFilter,
        this.page,
        coordinatorFilter
      )
      .subscribe(data => {
        this.requests.Count = data.Count;
        this.requests.Response = data.Response;

        this.rerender();
      });
  }

  hasAccess(moduleAction: string): boolean {
    return (
      this.permissions.findIndex(
        permission => permission.name === moduleAction
      ) > -1
    );
  }

  rerender(): void {
    this.chRef.detectChanges();
  }
}

enum requestServiceType {
  All = '0',
  Transport = '1',
  Customs = '2'
}
