import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { finalize, tap } from 'rxjs/operators';
import { SplashScreenService } from '../core/_base/layout';
import config from '../../../auth-config';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingService: SplashScreenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if( request.url == `https://${config.domain}/oauth/token`){
      return next.handle(request);
    } else {
      this.loadingService.show();
      this.totalRequests++;
    }
 
    

    return next.handle(request).pipe(tap (

      event => {  },

      error => { }

  ), 
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests === 0) {
          this.loadingService.hide();
        }
      })
    );
  }
}