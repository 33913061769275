export const trackAndTraceOptions = [
    {
      label: 'Localizar Carga por BL / Guía Aérea',
      subLabel: 'Para localizar la carga utilizando número de BL / Guía Aérea',
      route: '/locateBy/bl',
      icon: 'fa fa-arrow-right',
      class: 'btn btn-circle btn-lg btn-success'
    },
    {
      label: 'Localizar Carga por Orden de Compra (PO)',
      subLabel: 'Para localizar la carga utilizando número de orden (PO)',
      route: '/locateBy/po',
      icon: 'fa fa-arrow-right',
      class: 'btn btn-circle btn-lg btn-success'
    },
    {
      label: 'Localizar Carga por Conduce',
      subLabel: 'Para localizar la carga utilizando número de conduce',
      route: '/locateBy/conduce',
      icon: 'fa fa-arrow-right',
      class: 'btn btn-circle btn-lg btn-success'
    },
    {
      label: 'Localizar Carga por Contenedor',
      subLabel: 'Para localizar la carga utilizando número de contenedor',
      route: '/locateBy/container',
      icon: 'fa fa-arrow-right',
      class: 'btn btn-circle btn-lg btn-success'
    },
    {
      label: 'Localizar Carga por Orden de Servicio',
      subLabel: 'Para localizar la carga utilizando número de orden de servicio',
      route: '/locateBy/service',
      icon: 'fa fa-arrow-right',
      class: 'btn btn-circle btn-lg btn-success'
    }
  ];