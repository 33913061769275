import { OrderDetailModel } from './orderdetail';

export class OutboxModel{
    
    message_id:number;
    mardom_user_id :number;
    message_date:string;
    message_type :string;
    service_type :string;
    title :string;
    message :string;
    user_id:string;
    
}