export class CustomsModel{
    id: number;
    shipping_types: string;
    custom_management_types: string;
    product_type: string;
    require_special_permissions: number;
    special_permissions: string;
    records_average: string;
    other_services: string;
    other_services_desc: string;
    comments: string;
    user_id: string;
    created_date: string;
    status: number;
    assigned_user: string;
    management_type: string;
    arrival_date: string;
    reference: string;
    mardom_company_id: number;
    shipping_date: string;
    request_service: number;
    regimen: number;
}