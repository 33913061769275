import { Injectable } from '@angular/core';

import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TransportRequestService } from '../../../../services/transport-request/transport-request.service';
import * as TransportRequestActions from '../actions/transport-request.actions';

/* NgRx */
import { Actions, createEffect, ofType } from '@ngrx/effects';

@Injectable()
export class ServiceRequestEffects {

  constructor(private actions$: Actions, private baseService: TransportRequestService) { }

  getEquipments$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TransportRequestActions.getEquipments),
        mergeMap(() => this.baseService.getEquipments()
          .pipe(
            map(equipments => TransportRequestActions.getEquipmentsSuccess({ equipments }))            
          )
        )
      );
  });

  getEquipmentTypes$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TransportRequestActions.getEquipmentTypes),
        mergeMap(() => this.baseService.getEquipmentTypes()
          .pipe(
            map(equipmentTypes => TransportRequestActions.getEquipmentTypesSuccess({ equipmentTypes }))            
          )
        )
      );
  });

  getFreightLocations$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(TransportRequestActions.getFreightLocations),
        mergeMap(() => this.baseService.getFreightLocations()
          .pipe(
            map(freightLocations => TransportRequestActions.getFreightLocationsSuccess({ freightLocations }))            
          )
        )
      );
  });

}