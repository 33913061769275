import { PermissionWeb } from '../_models/permission.web.model';

export class PermissionsTable {
	public static permissions: PermissionWeb[] = [
        {
            id: 1,
            name: 'accessToDashboard',
            level: 1,
            title: 'Dashboard'
        },  
        {
            id: 2,
            name: 'accessToMyLocation',
            level: 1,
            title: 'Localiza tu carga'
        },  
        {
            id: 3,
            name: 'canReadWarehouseRequestsIn',
            level: 1,
            title: 'Consultar las solicitudes de almacén (entrada)'
        },
        {
            id: 4,
            name: 'canCreateWarehouseRequestsIn',
            level: 1,            
            title: 'Crear las solicitudes de almacén (entrada)'
        },    
        {
            id: 5,
            name: 'canReadWarehouseRequestsOut',
            level: 1,            
            title: 'Consultar las solicitudes de almacén (despacho)'
        },
        {
            id: 6,
            name: 'canCreateWarehouseRequestsOut',
            level: 1,
            title: 'Crear las solicitudes de almacén (despacho)'
        },      
        {
            id: 7,
            name: 'canReadServiceRequest',
            level: 1,
            title: 'Consultar las solicitudes de servicio'
        },
        {
            id: 8,
            name: 'canCreateImportServiceRequest',
            level: 1,            
            title: 'Crear solicitud de Gestión de Importación'
        },
        {
            id: 9,
            name: 'canCreateExportServiceRequest',
            level: 1,
            title: 'Crear solicitud de Gestión de Exportación'
        },
        {
            id: 10,
            name: 'canCreateLogisticServiceRequest',
            level: 1,
            title: 'Crear solicitud Servicios Logístico'
        },          
        {
            id: 11,
            name: 'accessToDesconsolidationEstimate',
            level: 1,            
            title: 'Consulta pantalla Estimado Desconsolidación de todos los clientes'
        },
        {
            id: 12,
            name: 'accessToReceipt',
            level: 1,
            title: 'Comprobantes'
        }, 
        {
            id: 13,
            name: 'canChangeRequestStatusAndResponsible',
            level: 1,            
            title: 'Cambio de estatus de solicitud y responsables.'
        },
        {
            id: 14,
            name: 'canSendMessages',
            level: 1,            
            title: 'Enviar mensaje.'
        },
        {
            id: 15,
            name: 'canSeePaymentReceipt',
            level: 1,            
            title: 'Ver comprobantes de pago.'        
        },
        {
            id: 16,
            name: 'canUploadPaymentReceipt',
            level: 1,            
            title: 'Subir comprobantes de pago.'
        },
        {
            id: 17,
            name: 'canAttachDocument',
            level: 1,            
            title: 'Adjuntar documento a la factura.'
        },
        {
            id: 18,
            name: 'canSeeAttachedDocuments',
            level: 1,            
            title: 'Ver documentos adjuntos.'
        },
        {
            id: 19,
            name: 'canPrintInvoice',
            level: 1,            
            title: 'imprimir factura.'        
        },
        {
            id: 20,
            name: 'canUseSaveButtomRequestServices',
            level: 1,            
            title: 'User boton de guardado request services.'
        }
    ];
}

export enum PermissionsEnum {
    accessToDashboard = 1,
    accessToMyLocation = 2,
    canReadWarehouseRequestsIn = 3,
    canCreateWarehouseRequestsIn = 4,
    canReadWarehouseRequestsOut = 5,
    canCreateWarehouseRequestsOut = 6,
    canReadServiceRequest = 7,
    canCreateImportServiceRequest = 8,
    canCreateExportServiceRequest = 9,
    canCreateLogisticServiceRequest = 10,
    accessToDesconsolidationEstimate = 11,
    accessToReceipt = 12,
    canChangeRequestStatusAndResponsible = 13,
    canSendMessages = 14,
    canSeePaymentReceipt = 15,
    canUploadPaymentReceipt = 16,
    canAttachDocument = 17,
    canSeeAttachedDocuments = 18,
    canPrintInvoice = 19,
    canUseSaveButtomRequestServices = 20,
}
