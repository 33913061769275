import { ITransportEquipment } from "./transport-equipment";

export class TransportRequestModel {
  blNumber: string;
  bookingNumber: string;
  deliveryDate: string;
  deliveryOrderNumber: string;
  mode: string
  requireWeighing: boolean;
  roundtrip: boolean;
  serviceDateFrom: string;
  serviceDateTo: string;
  serviceType: string
  shipline: string;
  vessel: string;
  voyage: string;
  equipments: ITransportEquipment[] 
  requestServiceId: number;
}