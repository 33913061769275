import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { TrackAndTraceService } from '../../services/track-and-trace.service';
import { trackAndTraceOptions } from '../locate-cargo/trackAndTraceOptions';
import { SplashScreenService } from '../../core/_base/layout';

@Component({
  selector: 'kt-locate-result',
  templateUrl: './locate-result.component.html',
  styleUrls: ['./locate-result.component.scss']
})
export class LocateResultComponent implements OnInit {

  type: string;
  option: any;

  result: [];

  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};

  typeEnpoint = {
    service: 'traceByOS',
    container: 'traceByContainer',
    conduce: 'traceByConduce',
    po: 'traceByPO',
    bl: 'traceByBL'
  }

  constructor(
    private activeRoute: ActivatedRoute,
    private trackAndTraceService: TrackAndTraceService,
    private splashScreenService: SplashScreenService
    ) { }

  ngOnInit() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering:  false,
      language: {
        "lengthMenu": "Mostrando _MENU_ registros por página",
        "zeroRecords": "No se encontraron registros",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No se encontraron registros",
        "infoFiltered": "(Filtrado de _MAX_ registros)",
        "paginate": {
            "previous": "Anterior",
            "next": "Siguiente"
        }
        
    }
    }
    this.dtTrigger.next();
    this.activeRoute.params.subscribe(params => {
      this.type = params['type'];  
      this.option = trackAndTraceOptions.find((option) => option.route == '/locateBy/' + this.type );          
      this.trackAndTraceService.getResultBy(this.typeEnpoint[this.type],'').subscribe((result) => {
        console.log(result)
        this.dtTrigger.next();
      })
    });
    
  }

}
