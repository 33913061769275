import { createReducer, on } from '@ngrx/store';
import { ServiceRequestState } from '../state/service-request.state';
import * as TransportRequestActions from '../actions/transport-request.actions';
import * as ServiceRequestActions from '../actions/service-request.actions';

const initState: ServiceRequestState = {
    equipmentTypes: [],
    equipments: [],
    freghtLocations: [],
    searchForm: {
      statusTypeFilter: [0],
      dateFrom: '',
      dateTo: '',
      requestId: '',
      clientFilter: [0],
      reference: '0',
      page: 1
    }
}

export const serviceRequestReducer = createReducer<ServiceRequestState>(
    initState,
    on(TransportRequestActions.getEquipmentsSuccess, (state, action): ServiceRequestState => {
        return {
            ...state,
            equipments: action.equipments
        }
    }),
    on(TransportRequestActions.getEquipmentTypesSuccess, (state, action): ServiceRequestState => {
        return {
            ...state,
            equipmentTypes: action.equipmentTypes
        }
    }),
    on(TransportRequestActions.getFreightLocationsSuccess, (state, action): ServiceRequestState => {
        return {
            ...state,
            freghtLocations: action.freightLocations.splice(0, 100)
        }
    }),
    on(ServiceRequestActions.setSearchForm, (state, action): ServiceRequestState => {
        return {
            ...state,
            searchForm: action.searchForm
        }
    }),
);