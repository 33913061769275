import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";

import {TransportModel} from '../../models/transport'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { selectAuthState } from '../../../app/core/auth/_selectors/auth.selectors';
import { TransportRequestModel } from '../../models/transport-request'

@Injectable({
  providedIn: 'root'
})
export class TransportService {

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.transport;
  uriTransportService = serviceConfig.altBaseUrl + serviceConfig.endPoints.transportRequestSave;
  headers = new HttpHeaders()
  altHeaders: HttpHeaders;

  constructor(private http:HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe((result) => { 
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);
      this.altHeaders =new HttpHeaders()
                      .append('Authorization', "Bearer "+result.authToken)  
                      .append('accept','*/*')
                      .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                      .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                      .append('accept','text/plain');
    })
  }


  addTransport(transport: TransportModel){    
    this.http.post(`${this.uri}`, transport, { headers: this.altHeaders }).subscribe(res => console.log('Done'));
  }
  updateTransport(id:number ,transport: TransportModel){
    this.http.put(`${this.uri}/${id}`, transport, { headers: this.altHeaders }).subscribe(res => console.log('Done'));;
  }
  createTransportRequest(transport: TransportRequestModel):Observable<any>{
   return this.http.post(`${this.uriTransportService}`, transport, { headers: this.altHeaders })
  }

  getTransport(requestId:number):Observable<any> {
    return this
      .http
      .get<any>(`${this.uri}?RequestId=${requestId}`,
       { headers: this.altHeaders });

  }
  getTmsServiceType():Observable<any> {
    return this
      .http
      .get<any>(`${serviceConfig.altBaseUrl}${serviceConfig.endPoints.tmsServiceType}`,
       { headers: this.altHeaders });
  }
  getTmsShipLines():Observable<any> {
    return this
      .http
      .get<any>(`${serviceConfig.altBaseUrl}${serviceConfig.endPoints.tmsShipLine}`,
       { headers: this.altHeaders });
  }
}
