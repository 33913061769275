export enum Action {
    AccederPantalla = '9760A31D-F86D-44C7-8085-652311CA71C4',
    CrearSolicitudServicio = 'B39A12D9-B0EF-4607-B462-430B6F8474D7',
    VerSolicitudServicio = '12F0B3B6-D449-424F-A635-633822F0D66E',
    VerDetalleDespachoEntradaAlmacen = '19354C14-02FD-4700-920A-47F34114A6D5',
    EnvioMensaje = '2229E657-BB69-4585-B1E0-4CE476D504B9',
    CrearDespachoAlmacen = '6F1F7708-3145-464F-BAED-1FF25C526A08',
    CrearEntradaAlmacen = '16D61A7E-3A0E-4D9E-9FB0-65CCEE007A2C',
    SubirComprobantePago = '5E95009B-50BC-4131-8A49-E49792281F07',
    CrearSolicitudBookingExportacion = '522F37CC-A3E8-412E-8E9A-73CD5B5946C7',
    CrearSolicitudBookingImportacion = 'AF02F007-C0DB-4443-9097-3263A3017777',
    CrearSolicitudServiciosLogisticos = '2BD63CDC-7689-4552-8165-BCCA9DC54BA0',
    ActualizarSolicitudBookingExportacion = '35742BB0-A40D-479E-8465-8EBC77D033E0',
    ActualizarSolicitudBookingImportacion = '84655D04-B7C9-4781-A846-56F2C1B467F2',
    ActualizarSolicitudServiciosLogisticos = 'E6900749-E9F1-46CC-A81B-FB58D4BA584E',
    AbortarCreacionSolicitudBookingExportacion = 'F99C8387-57E7-4832-90B0-9A8686907F7F',
    AbortarCreacionSolicitudBookingImportacion = 'B8453876-4DAC-4E10-802E-7B8A069EF69F',
    AbortarCreacionSolicitudServiciosLogisticos = '2CAEBCB6-B6F0-4ED1-940A-F9D077FFC058',
    AbortarCreacionSolicitudBookingExportacionAduana = 'E888CD87-21F4-4081-95A7-63EF27805E4D',
    AbortarCreacionSolicitudBookingExportacionTransporte = '3A637091-AFA6-4359-928D-7C9AB258A1C8',
    AbortarCreacionSolicitudBookingExportacionMixto = 'F397AF9D-2623-4443-B2AA-B9916BC4218E',
    AbortarCreacionSolicitudBookingImportacionAduana = 'D35E142C-A3EC-4316-A86A-8083D92ABEEF',
    AbortarCreacionSolicitudBookingImportacionMixto = 'AC3F956D-2E4D-47B9-9140-31B533261AD4',
    AbortarCreacionSolicitudBookingImportacionTransporte = 'D3794B92-B349-4D30-8A6D-07E4F1BA8621',        
    AbortarCreacionSolicitudServiciosLogisticosTransporte = 'EB6B10C8-52FD-4190-BA4B-752D89BB6B69',
    ActualizarSolicitudBookingExportacionAduana = 'ECC61443-42ED-46FF-993D-4CA03887750F',
    ActualizarSolicitudBookingExportacionMixto = '351E30F0-11F8-4079-87B2-8BBF6D49289C',
    ActualizarSolicitudBookingExportacionTransporte = 'A33F5F59-9BFF-44CB-8A9A-FBBD7CEA1450',
    ActualizarSolicitudBookingImportacionAduana = '09A3C854-C815-4445-9BE2-B228DEBB9D87',
    ActualizarSolicitudBookingImportacionMixto = 'B48E81A6-4555-4E6E-98BA-C293A1472FDD',
    ActualizarSolicitudBookingImportacionTransporte = 'D5BD216D-7BA1-406F-A276-C328EB25EF7F',
    ActualizarSolicitudServiciosLogisticosTransporte = 'E20ACCC5-7F49-4001-8496-42B7BF5163AC',
    CrearSolicitudBookingExportacionAduana = 'FCE06838-5C7D-4F91-A2FE-D8687DDA02A8',
    CrearSolicitudBookingExportacionMixto = '5B3E4505-754D-414E-A877-982D09274F3F',
    CrearSolicitudBookingExportacionTransporte = '8DD85788-CA56-4F22-8DFE-5F5244EAA67A',
    CrearSolicitudBookingImportacionAduana = '83E57704-B914-4A68-BAD6-9A2912FCB975',
    CrearSolicitudBookingImportacionMixto = '5D073901-6BC4-4A90-A104-409F65FD48EA',
    CrearSolicitudBookingImportacionTransporte = 'E2254A71-F272-4CFB-85CF-93E3DCDDE088',
    CrearSolicitudServiciosLogisticosTransporte = '90636BD5-6E7D-47D3-9C2D-18F9E95E9D4E',
    ImprimirEstimadoDesconsolidacion = 'EE728487-590B-4131-A93F-896141BFE0D1',
    ImprimirFactura = '5F91554B-5903-4A46-9749-2044705430E5',
}