import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { AppState } from '../../core/reducers/index';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})

export class ClientService {

  alturi = serviceConfig.altBaseUrl;

  altHeaders: HttpHeaders;
  public userId;

  
  constructor(private http:HttpClient, private store: Store<AppState> ) { 
    this.store.select(selectAuthState).subscribe((result) => {   
      var decoded = jwt_decode(result.authToken); 
      //real userid.
      this.userId=decoded['sub'];

      this.altHeaders = new HttpHeaders()
                          .append('Authorization', "Bearer "+result.authToken)  
                          .append('Content-Type', 'application/json')
                          .append('accept','*/*')
                          .append('accept','text/plain');
    });
  }
  getCurrentUser(){
    return this.userId;
  }
  getCustomers () {
   let url = this.alturi + serviceConfig.endPoints.customers;  
    return this.http.get(url, { headers: this.altHeaders});
  }

  getEmployee () {
    let url = this.alturi + serviceConfig.endPoints.employeesAccount;
    
    return this.http.get(url, { headers: this.altHeaders});
  }

}
