import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse,HttpResponse,HttpRequest} from "@angular/common/http";

import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';
import * as jwt_decode from 'jwt-decode';
import { BillVoucherModel } from '../../models/billvoucher';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  uri = serviceConfig.altBaseUrl ;
  billuri = serviceConfig.baseUrl + serviceConfig.endPoints.billVoucher;
  headers = new HttpHeaders()
  altHeaders: HttpHeaders;
  userId:'';
  clientName:'';
  customerId:'';

  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {  
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);

      var decoded = jwt_decode(result.authToken); 
      //real userid.
      this.userId=decoded['https://mardom.com/clientId'];
      var decodediD = jwt_decode(result.idToken); 
      
      this.clientName=decodediD['name'];
      this.customerId=decodediD['sub'];
      
      this.altHeaders =new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('Content-Type', 'application/json')
                    .append('accept','*/*')
                    .append('Allow', '*')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
    });
  }

  addBillVoucher(voucherModel:BillVoucherModel){
    voucherModel.user_id=this.userId;
    voucherModel.client_name=this.clientName;
    voucherModel.customer_id=this.customerId;
    
    return this.http.post(`${this.billuri}`, voucherModel, { headers: this.altHeaders });
  }

  getPendingInvoices(page: number, invoiceNumber: string):Observable<any> {    
    let url= this.uri + serviceConfig.endPoints.pendingInvoice;
    return this
      .http
      .get<any>(`${url}?page=${page}&invoiceNumber=${invoiceNumber}`,
       { headers: this.altHeaders });
  }

  downloadBill(id:number): Observable<any>{
    let url= this.uri + serviceConfig.endPoints.downloadInvoiceId;
		return this.http.get(`${url}/${id}`, { headers: this.altHeaders, responseType:'blob'});
  }

  downloadDynamicBill(id:number): Observable<any>{
    let url= this.uri + serviceConfig.endPoints.downloadInvoiceDynamic;    
		return this.http.get(`${url}/${id}`, { headers: this.altHeaders, responseType:'blob'});
  }

  downloadBillFreightForward(id:string):Observable<any> {
    let url= this.uri + serviceConfig.endPoints.downloadInvoiceFF;    
    return this.http.get(`${url}/${id}`, { headers: this.altHeaders, responseType:'blob'});
  }
  

}
