import { BaseModel } from '../../_base/crud';
import { Address } from './address.model';
import { Permission } from './permission.model';
import { SocialNetworks } from './social-networks.model';

export class User extends BaseModel {
  id?: string;
  username?: string;
  password?: string;
  email?: string;
  pic?: string;
  fullname?: string;
  occupation?: string;
  companyName?: string;
  phone?: string;
  address?: Address;
  permissions?: Permission[];
  socialNetworks?: SocialNetworks;
}
