import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {EstimateService } from '../../services/estimates/estimate.service';
import { DataTableDirective } from 'angular-datatables';
import { FormBuilder, FormGroup ,ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { select, Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import { AppState } from '../../core/reducers/index';
import { currentUserRoleIds,selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { LogService } from '../../services/log/log.service';
import { Action } from '../../models/enums/action.enum';
import { Screen } from '../../models/enums/screen.enum';
import { LogDto } from '../../models/dto/log.dto';

@Component({
  selector: 'kt-panel',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class EstimateComponent implements OnInit {

  optionSelected:  string = '';

  @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
  dtTrigger : Subject<any> = new Subject();

  dollarExchangeRate = 0;
  lastUpdated = 'cargando...';
  estimates = [];
  quotations = {};
  currentEstimate;
  estimatesForm: FormGroup;
  quotationForm: FormGroup;
  adminFilter=false;
  errorMessage:boolean = false;
  showLoading:boolean = false;  

  logisticServices = [];
  dtOptions: any = {};
  blDTOptions: any = {};
  errorQuotation:string

  today = new Date();
  payload$: any;
  

  constructor(private router: Router,private estimateService:EstimateService,
    private fb:FormBuilder,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private logService: LogService,
    private chRef: ChangeDetectorRef) { 
    
     
  }

  ngOnInit() {
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.estimatesForm=this.fb.group({
      blNumber: '',
      tarjaNumber:''
    }); 
    this.quotationForm=this.fb.group({
      cifNumber: '',
      dispatchDate:''
    }); 

    this.blDTOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering:  false,
      language: {
        "lengthMenu": "Mostrando _MENU_ registros por página",
        "zeroRecords": "...",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "..",
        "infoFiltered": "(Filtrado de _MAX_ registros)",
        "search":"Filtrar",
        "paginate": {
            "previous": "Anterior",
            "next": "Siguiente",
            "first": "Primera",
          "last": "Ultima"
        }
      }
    }
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering:  false,
      columnDefs: [
        {
            targets: 5,
            width: '200px'
        }],
    language: {
      "lengthMenu": "Mostrando _MENU_ registros por página",
      "zeroRecords": "...",
      "info": "Mostrando página _PAGE_ de _PAGES_",
      "infoEmpty": "..",
      "infoFiltered": "(Filtrado de _MAX_ registros)",
      "search":"Filtrar",
      "paginate": {
          "previous": "Anterior",
          "next": "Siguiente",
          "first": "Primera",
          "last": "Ultima"
      }
    }
  }
  this.checkUserRole();
    //this.getEstimates();
    this.searchByBlTally();

    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.AccederPantalla,
        ScreenId : Screen.EstimadoDesconsolidación,
        Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    })
}

//searchByBlTally, dar prioridad a tarja
searchByBlTally() {
    
    if(this.estimatesForm.value.tarjaNumber!=null && this.estimatesForm.value.tarjaNumber!=''){
     this.estimateService
      .getDataByTally(this.estimatesForm.value.tarjaNumber)
        .subscribe(
          (data) => {            
            this.estimates = [];
            this.estimates.push(data);
            this.errorMessage = false
            this.rerender();          
          },
          (error) => {           
            this.estimates = [];
            this.errorMessage = true
            this.rerender();          
            this.showError('* El recurso que intenta acceder no existe o no está asociado a su empresa para poder visualizarlo.');
          }
        );
    }else if(this.estimatesForm.value.blNumber!=null && this.estimatesForm.value.blNumber!=''){
      //this.estimatesForm.value.blNumber
      this.estimateService
      .getDataByBL(this.estimatesForm.value.blNumber)
        .subscribe((data) => {          
          this.estimates = [];
          this.estimates.push(data);
          this.errorMessage = false
          this.rerender();
          //this.chRef.detectChanges();
        },
        (error) => {
          this.estimates = [];
          this.errorMessage = true
          this.rerender();        
          this.showError('* El recurso que intenta acceder no existe o no está asociado a su empresa para poder visualizarlo.');  
        }
        );
    }else{
      this.estimateService
      .getAllData()
        .subscribe((data) => {          
          this.estimates = data;
          this.errorMessage = false
          this.rerender();
          //this.chRef.detectChanges();
        },
        (error) => {
          this.estimates = [];
          this.errorMessage = true
          this.rerender();          
          this.showError('* El recurso que intenta acceder no existe o no está asociado a su empresa para poder visualizarlo.');
        });
    }
  }

  checkUserRole(){
    this.store.select(selectAuthState).subscribe((result) => {   
      var decoded = jwt_decode(result.authToken); 
      //real userid.
      var currentRoles= decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      var internal=currentRoles.indexOf("Client");
      
      if(!(internal>-1)){
        this.adminFilter=true;
      }
      else{
        this.adminFilter=false;
      }
     
    })
  }

  startQuotation(estimate){    
    this.currentEstimate=estimate;
   
    this.quotationForm.reset();

    this.quotations = {};
    this.chRef.detectChanges();

  }
  downloadReport(id){    
    if(id){
      this.estimateService.downloadReport(id).subscribe(response => {
        this.payload$.subscribe(res => {
          let tokenData = jwt_decode(res.authToken)
          this.logService.postWithPayload(<LogDto>{
            ActionId : Action.ImprimirEstimadoDesconsolidacion,
            ScreenId : Screen.EstimadoDesconsolidación,
            Json : JSON.stringify(id),
            UserName: res.user.fullname,
            Email: res.user.email,
            ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
            RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
          },
          res.authToken);
        })
        const url= window.URL.createObjectURL(response);
        window.open(url);    
      },(error) => {
        this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
      })
    }else{
      this.toastr.info('No existe una cotización relacionada a este estimado de desconsolidación','Aviso.');      
    }
  }
  showError(message) {
    this.toastr.error(message, 'Error.');
  }
  getQuotation(){    
    this.quotations = {};

    if(this.quotationForm.value.dispatchDate!=null && this.quotationForm.value.dispatchDate!='' && 
    this.quotationForm.value.cifNumber!=null && this.quotationForm.value.cifNumber!=''){
      this.showLoading = true;
      this.estimateService
      .getQuotationData(this.currentEstimate.clientId,this.currentEstimate.id,this.quotationForm.value.dispatchDate,
        this.quotationForm.value.cifNumber,this.quotationForm.value.dispatchDate)
        .subscribe((data) => {
          this.showLoading = false;
          this.errorQuotation = undefined
          this.quotations=data;
          this.chRef.detectChanges();
        },
        error => {
          this.showLoading = false;
          this.chRef.detectChanges();
          switch (error.status) {
            case 404:
              return this.toastr.info('Aún esta mercancía no se encuentra en el almacén', 'Aviso.');            
            case 0:
              return this.toastr.error('Oops! Ha ocurrido un error.', 'Error.');                          
            case undefined:
              return this.toastr.error('Oops! Ha ocurrido un error.', 'Error.');  
            default:            
              if(error.errorMessage){
                this.errorQuotation = error.errorMessage
                this.toastr.info(this.errorQuotation, 'Aviso.');
              }                        
          }          
        });

    }else{
      this.showLoading = false;
      this.chRef.detectChanges();
      this.showError('Debe introducir un CIF y una fecha');
    }
    
  }
  rerender(): void {
    try{
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first 
        dtInstance.destroy();
        
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
        this.chRef.detectChanges();
      });

    } catch(e){
      this.chRef.detectChanges();
    }
   
  }

  ngAfterViewInit(): void {

    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
}

  gotToRoute(){
    if(this.optionSelected){
      this.router.navigate([this.optionSelected]);
    }
  }

}
