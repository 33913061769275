import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Observable,
  of,
  from,
  BehaviorSubject,
  zip,
} from 'rxjs';
import { User } from '../_models/user.model';
import { Permission } from '../_models/permission.model';
import { map, shareReplay } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
import { environment } from '../../../../environments/environment';
import { Router, NavigationExtras } from '@angular/router';
import config from '../../../../../auth-config';
import { AppState } from '../../reducers/index';
import { select, Store } from '@ngrx/store';
import {
  Login,
  BeginLogin,
  Logout,
} from '../_actions/auth.actions';
import {
  isLoggedIn,
  currentIdToken
} from '../_selectors/auth.selectors';
import auth0 from 'auth0-js';
import * as jwt_decode from 'jwt-decode';
import * as uuid from 'uuid/v4';
import * as base64 from 'base-64';
import { currentAuthToken } from '../_selectors/auth.selectors';
import { RefreshTokens } from '../_actions/auth.actions';
import { PermissionWeb } from '../_models/permission.web.model';
import serviceConfig from '../../../services/service-config';
import { PermissionsTable } from '../_server/permissions.table';
import { RolesTable } from '../_server/roles.table';
import { registerLocaleData } from '@angular/common';
import localeDo from '@angular/common/locales/es-DO';
import { LogDto } from '../../../models/dto/log.dto';
import { Action } from '../../../models/enums/action.enum';
import { Screen } from '../../../models/enums/screen.enum';
import { LogService } from '../../../services/log/log.service';

registerLocaleData(localeDo, 'es-DO');
// NGRX
const API_USERS_URL = 'api/users';

@Injectable({ providedIn: 'root' })
export class AuthService {
  auth0Client = from(this.createAuth0Client()).pipe(
    shareReplay(1)
  ) as Observable<any>;

  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  userId: any;
  roles: string[];

  get tokenExpirationDate(): Date {
    const storage = localStorage.getItem(environment.tokensExpirationDateKey);
    const date = new Date(JSON.parse(storage));
    return date;
  }
  set tokenExpirationDate(value: Date) {
    const serializedDate = JSON.stringify(value);
    localStorage.setItem(environment.tokensExpirationDateKey, serializedDate);
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
    private logService: LogService
  ) {
    this.OnInit();
  }

  private OnInit() {
    this.handleAuthCallback();    
    this.store.select(isLoggedIn).subscribe(loggedIn => {
      if (loggedIn) {        
        this.setRefresTokenTimer();
      }
    });
  }

  private async createAuth0Client() {
    const client = new auth0.WebAuth({
      domain: config.domain,
      clientID: config.clientId,
      audience: config.audience,
      scope: config.scope
    });
    return client;    
  }

  private handleAuthCallback() {
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      this.store.dispatch(new BeginLogin({}));
      const urlParams = new URLSearchParams(params);
      const code = urlParams.get('code');
      const state = JSON.parse(base64.decode(urlParams.get('state')));
      const antiCSRFToken = localStorage.getItem(environment.antiCSRFTokenKey);
      if (state.antiCSRFToken !== antiCSRFToken) {
        this.store.dispatch(new Logout());
        throw new Error('Invalid anti CSRF token');
      }
      localStorage.removeItem(environment.antiCSRFTokenKey);
      const suscription = this.http
        .post(`https://${config.domain}/oauth/token`, {
          grant_type: 'authorization_code',
          client_id: config.clientId,
          code,
          scope: config.scope,
          redirect_uri: config.redirectUri
        })
        .subscribe(
          (response: any) => {
            this.setTokenExpirationDate(response.expires_in);
            this.store.dispatch(
              new Login({
                accessToken: response.access_token,
                idToken: response.id_token
              })
            );          
            this.router.navigate([`${state.redirectUri}`]);
          },
          error => {
            this.store.dispatch(new Logout());
          },
          () => {
            suscription.unsubscribe();
          }
        );
    } else if (params.includes('error=')) {
			const urlParams = new URLSearchParams(params)
			const error = urlParams.get('error');
			const errorDescription = urlParams.get('error_description');
      const navigationExtra: NavigationExtras = { state: { error: error, errorDescription: errorDescription}}
			this.router.navigate(['error/403'], navigationExtra);
		}
  }

  private getUserPermissionsByRole(roles: string[]){
    this.roles = roles
    this.getRolePermissions();
  }

  login(redirectUri: string = '/', silent: boolean = false) {
    const antiCSRFToken = uuid();
    localStorage.setItem(environment.antiCSRFTokenKey, antiCSRFToken);
    const state = base64.encode(
      JSON.stringify({
        redirectUri,
        antiCSRFToken
      })
    );
    this.auth0Client.subscribe(client => {
      const loginOptions = {
        audience: config.audience,
        connection: config.connection,
        redirectUri: config.redirectUri,
        responseType: 'code',
        state
      };
      if (silent) {
        Object.assign(loginOptions, { prompt: 'none' });
      }
      client.authorize(loginOptions);
    });
  }

  private setTokenExpirationDate(seconds: number) {
    const date = new Date();
    date.setSeconds(date.getSeconds() + seconds);
    this.tokenExpirationDate = date;
  }

  private getTokenRemainingTime() {
    if (!this.tokenExpirationDate) {
      return;
    }
    const currentDate = new Date();
    const remainingMiliSeconds =
      this.tokenExpirationDate.getTime() - currentDate.getTime();
    return remainingMiliSeconds;
  }

  private setRefresTokenTimer() {
    const remaining = this.getTokenRemainingTime();
    if (!remaining) {
      return;
    }
    if (remaining > 0) {
      setTimeout(() => this.refreshTokens(), remaining);
    } else {
      setTimeout(() => this.refreshTokens());
    }
  }

  refreshTokens() {
    const authParams = {
      responseType: 'token id_token',
      clientID: config.clientId,
      redirectUri: config.redirectUri,
      scope: config.scope,
      audience: config.audience,
      responseMode: 'web_message',
      prompt: 'none'
    };
    this.auth0Client.subscribe(
      client => {
        client.checkSession(authParams, (error, authResult) => {
          if (authResult) {
            this.store.dispatch(
              new RefreshTokens({
                idToken: authResult.idToken,
                accessToken: authResult.accessToken
              })
            );
            this.setTokenExpirationDate(authResult.expiresIn);
            this.setRefresTokenTimer();
          }
          if (error) {
            this.store.dispatch(new Logout());
          }
        });
      },
      err => {
        console.error(err);
      }
    );
  }

  validateTokenLifetime(token: string) {
    const tokenPayload = jwt_decode(token);
    const currentTime = new Date().getTime() / 1000;
    return tokenPayload.exp > currentTime;
  }

  logout() {
    this.auth0Client.subscribe(client => {
      client.logout({
        returnTo: config.redirectUri,
        clientID: config.clientId
      });
    });
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(API_USERS_URL + `/${userId}`);
  }

  // DELETE => delete the user from the server
  deleteUser(userId: number) {
    const url = `${API_USERS_URL}/${userId}`;
    return this.http.delete(url);
  }

  // Method from server should return QueryResultsModel(items: any[], totalsCount: number)
  // items => filtered/sorted result
  findUsers(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    throw new Error('Method not implemented.');
  }

  // Permission
  getAllPermissions(): Observable<PermissionWeb[]> {
    return Observable.of(PermissionsTable.permissions);
  }

  getRolePermissions(): Observable<PermissionWeb[]> {        
    if(this.roles == undefined){
      return Observable.of(PermissionsTable.permissions); 
    }

    const role = RolesTable.roles.find(item => item.title == this.roles)        
    if(role == undefined){
      return Observable.of(PermissionsTable.permissions); 
    }else{
      return Observable.of(PermissionsTable.permissions.filter(item => role.permissions.indexOf(item.id) > -1));
    }    
  }

  // Check Role Before deletion
  isRoleAssignedToUsers(roleId: number): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  findRoles(queryParams: QueryParamsModel): Observable<QueryResultsModel> {
    throw new Error('Method not implemented.');
  }

  getUser() {
    const user = zip(
      this.store.select(currentIdToken),
      this.store.select(currentAuthToken)
    ).pipe(
      map(([idToken, authToken]) => {
        const idTokenInfo = jwt_decode(idToken);
        const authTokenInfo = jwt_decode(authToken);        
        const permissions = authTokenInfo.scope
          .split(' ')
          .map<Permission>(permission => {
            return { scope: permission };
          });
       this.roles = authTokenInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
       this.userId = idTokenInfo.sub;
       this.getRolePermissions()
        return {
          id: idTokenInfo.sub,
          email: idTokenInfo.email,
          fullname: idTokenInfo.name,
          username: idTokenInfo.nick_name,
          pic: idTokenInfo.picture,
          permissions
        } as User;
      })
    );
    return user;
  }
  private handleError<T>(operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }
}
