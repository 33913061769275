import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";

import {OrderModel} from '../../models/order'
import {OrderDetailModel} from '../../models/orderdetail'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.orderEntry;
  alturi = serviceConfig.altBaseUrl;
  altHeaders: HttpHeaders;
  headers: HttpHeaders;

  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {   
      this.altHeaders = new HttpHeaders()
                          .append('Authorization', "Bearer "+result.authToken)  
                          .append('Content-Type', 'application/json')
                          .append('accept','*/*')
                          .append("Access-Control-Allow-Origin", "*")
                          .append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
                          .append('accept','text/plain');


      this.headers =new HttpHeaders()
                          .append('Authorization', "Bearer "+result.authToken)  
                          .append("Access-Control-Allow-Origin", "*")
                          .append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
                          .append('accept','*/*')
                          .append('accept','text/plain');
    });
  }

  addOrderEntry(order:OrderModel){
    this.http.post(`${this.uri}`, order, { headers: this.altHeaders }).subscribe(res => console.log('Done'));;
  }
  sentOrderWis(order:OrderModel){
    let url = this.alturi + serviceConfig.endPoints.wareHouseOrder;
    return this.http.post(`${url}`, order, { headers: this.altHeaders });
  }

  getOrderList(orderId:number,from:string,to:string,status:number,customer:number, pageNumber : number, pageSize : number) {
    
    let url = this.alturi + serviceConfig.endPoints.wareHouseOrder;
    return this
      .http
      .get<any[]>(`${url}/?startDate=${from}&endDate=${to+" 23:59:59"}&status=${status}&requestNumber=${orderId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{ headers: this.altHeaders });
  }

  getOrderDetail(orderId:number) {
    let url = serviceConfig.baseUrl + serviceConfig.endPoints.orderDetail;
    return this
      .http
      .get<any[]>(`${url}/?orderId=${orderId}`, { headers: this.altHeaders});
  }

}
