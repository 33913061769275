import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LogDto } from '../../models/dto/log.dto';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers';
import serviceConfig from '../service-config';
import { UserDto } from '../../models/dto/user.dto';
import { environment } from '../../../environments/environment';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  headers: HttpHeaders;  
  user: UserDto
  baseUrl: string;
  tokenData: any;

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe((result) => {      
      this.user = result.user;
      this.tokenData = jwt_decode(result.authToken);
      this.headers = new HttpHeaders()
        .append('Authorization', "Bearer " + result.authToken)
        .append('Content-Type', 'application/json')
        .append('accept', '*/*')
        .append("Access-Control-Allow-Origin", "*")
        .append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
        .append('accept', 'text/plain');
    })    
    this.baseUrl = serviceConfig.altBaseUrl+serviceConfig.endPoints.log;
  }

  post(payload: LogDto) {    
    let result = this.setPayloadSessionData(payload);
    if(result){
      this.http.post(`${this.baseUrl}`, payload, { headers: this.headers }).subscribe(
        () => {},
        () => console.error('Could not save log')
      );
    }
  }

  postWithPayload(payload: LogDto, token: string) {
    payload.Browser = this.getBrowser();
    this.http.post(`${this.baseUrl}`, payload, { headers: new HttpHeaders().append('Authorization', `Bearer ${token}`) }).subscribe(
      () => {},
      () => console.error('Could not save log')
    );
  }

  private setPayloadSessionData(payload: LogDto): Boolean {
    if(!this.user) return false;

    payload.UserName = this.user.fullname;
    payload.Email = this.user.email;
    payload.ClientId = this.tokenData['https://mardom.com/clientId'] == '' ? null : this.tokenData['https://mardom.com/clientId'];
    payload.Browser = this.getBrowser();
    payload.RoleName = this.tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0];

    return true;
  }

  private getBrowser(): string {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge'
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'Opera'
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'Chrome'
      case agent.indexOf('trident') > -1:
        return 'IE'
      case agent.indexOf('firefox') > -1:
        return 'Firefox'
      case agent.indexOf('safari') > -1:
        return 'Safari'
      default:
        return 'Other'
    }
  }
}
