import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import { AppState } from '../../../core/reducers/index';
import { currentUserRoleIds,selectAuthState } from '../../../core/auth/_selectors/auth.selectors';
import { selectAllPermissions } from '../../../core/auth';
import { SidebarService } from '../../../services/sidebar.service';
import { Router } from '@angular/router';


@Component({
  selector: 'kt-mdmaincontainer',
  templateUrl: './mdmaincontainer.component.html',
  styleUrls: ['./mdmaincontainer.component.scss']
})
export class MdmaincontainerComponent implements OnInit {

  showMobileMenu: boolean;

  constructor(private store: Store<AppState>, private sidebarService: SidebarService, private router: Router) { 
    this.sidebarService.sidebarVisibilityChange.subscribe((value) => {      
        this.showMobileMenu = value;
    });
    this.router.events.subscribe(() => {
      this.sidebarService.closeSideBar();
    })
    
  }

  EntradaAlmacen: boolean = false
  DespachoAlmacen: boolean = false
  SolicitudServicio: boolean =false
  SolicitudTrasaloda: boolean= false
  adminFilter=false;
  permissions: any[]
  isExpand: boolean = true

  ngOnInit() {
    this.checkUserRole();
    let menuState = localStorage.getItem('nav-condition');
    if(menuState == 'colapse') this.isExpand = false;
    this.store.select(selectAllPermissions).subscribe(
      res => {
        this.permissions = res        
      }
    );
  }

 hasAccess(moduleAction: string):boolean {    
  return this.permissions.findIndex(permission => permission.name === moduleAction) > -1
 }

  checkUserRole(){
    this.store.select(selectAuthState).subscribe((result) => {   
      var decoded = jwt_decode(result.authToken); 
      
      var currentRoles= decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];      

      var internal=currentRoles.indexOf("Client");
      
      if(!(internal>-1)){
        this.adminFilter=true;
      }
      else{
        this.adminFilter=false;
      }     
    })    
  }
}
