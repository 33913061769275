import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";

import {ContactModel} from '../../models/contact'
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app/core/reducers';
import { selectAuthState } from '../../../app/core/auth/_selectors/auth.selectors';

@Injectable({
  providedIn: 'root'
})

export class ContactService {
  altHeaders: HttpHeaders;
  headers = new HttpHeaders()  

  constructor(private store: Store<AppState>, private http:HttpClient) {    
    this.store.select(selectAuthState).subscribe((result) => { 
      this.headers = new HttpHeaders().set('Authorization', "Bearer "+result.authToken);
      this.altHeaders =new HttpHeaders()
                      .append('Authorization', "Bearer "+result.authToken)  
                      .append('accept','*/*')
                      .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                      .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                      .append('accept','text/plain');
    })
  }

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.contact;
  addContact(contact: ContactModel){
    return this.http.post(`${this.uri}`, contact, { headers: this.altHeaders });
  }

  updateContact(id:number ,contact: ContactModel){
    this.http.put(`${this.uri}/${id}`, contact, { headers: this.altHeaders }).subscribe(res => console.log('Done'));
  }

  getContact(contactId:number):Observable<any> {
    return this
      .http
      .get<any>(`${this.uri}/${contactId}`,
       { headers: this.altHeaders });

  }

}
