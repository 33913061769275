import { createAction, props } from '@ngrx/store';

import { IEquipment } from "../../../../models/transport-request/equipment";
import { IEquipmentType } from "../../../../models/transport-request/equipment-type";
import { IFreightLocation } from "../../../../models/transport-request/freight-location";
import { SearchForm } from '../../models/searchFormDto';

export const getEquipments = createAction(
    '[TransportRequest Page] Get Equipments'
);

export const getEquipmentsSuccess = createAction(
    '[TransportRequest API] Get Equipments Success',
    props<{ equipments: IEquipment[] }>()
);

export const getEquipmentTypes = createAction(
    '[TransportRequest Page] Get EquipmentTpes'
);

export const getEquipmentTypesSuccess = createAction(
    '[TransportRequest API] Get EquipmentTypes Success',
    props<{ equipmentTypes: IEquipmentType[] }>()
);

export const getFreightLocations = createAction(
    '[TransportRequest Page] Get FreightLocations'
);

export const getFreightLocationsSuccess = createAction(
    '[TransportRequest API] Get FreightLocations Success',
    props<{ freightLocations: IFreightLocation[] }>()
);