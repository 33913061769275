import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ProductService } from '../../services/warehouse/product.service';
import { OrderService } from '../../services/warehouse/order.service';
import { ReceptionreferenceService } from '../../services/warehouse/receptionreference.service';
import { AgentsService } from '../../services/warehouse/agents.service';
import { OrderModel } from '../../models/order';
import { OrderDetailModel } from '../../models/orderdetail';

import { ReceptionReferenceModel } from '../../models/receptionreference';
import { ReceptionDetailModel } from '../../models/receptiondetail';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { LogService } from '../../services/log/log.service';
import { LogDto } from '../../models/dto/log.dto';
import { Action } from '../../models/enums/action.enum';
import { Screen } from '../../models/enums/screen.enum';
import { select, Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'kt-storage-detail',
  templateUrl: './storage-detail.component.html',
  styleUrls: ['./storage-detail.component.scss']
})
export class StorageDetailComponent implements OnInit {

  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  private sub: any;
  storageForm: FormGroup;
  title: string;
  type: string;
  regimenSelected: string;
  productList: any[];
  productListResult: any[];
  productListSelected = {};
  orderEntry: OrderModel;
  orderDetail: OrderDetailModel;
  receptionModel: ReceptionReferenceModel;
  receptionDetail: ReceptionDetailModel;
  agentList: any[];
  agentSelected;

  currentStep = 0;

  productSearch: string;

  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};

  singleSelectOptions = {
    width: '100%',
    multiple: false,
    placeholder: 'Selecccionar...'
  }
  invalidData: string;
  payload$: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private agentService: AgentsService,
    private orderService: OrderService,
    private toastr: ToastrService,
    private receptionreferenceService: ReceptionreferenceService,
    private fb: FormBuilder,
    private chRef: ChangeDetectorRef,
    private logService: LogService,
    private store: Store<AppState>,
    private elementRef: ElementRef
  ) {
    this.sub = this.activeRoute.params.subscribe(params => {
      this.type = params['type'];

      this.title = (this.type == 'entrance' ? 'Solicitud de entrada de almacén' : "Solicitud de despacho de almacén");
    });
  }

  previusStep() {
    this.currentStep--;
    this.chRef.detectChanges();
  }

  nextStep() {    
    if(this.invalidData) {
      Swal.fire('Aviso.', this.invalidData, 'info');
      return;
    }
    if (this.type == "entrance") {

      if (this.storageForm.value.referenceNumber == '' || this.regimenSelected == null || this.regimenSelected == '0' || this.agentSelected == null
        || this.storageForm.value.fechaEntrega == '' || this.storageForm.value.memo == '') {
        this.toastr.info('Favor llenar todos los campos requeridos.');        
        return;
      }
    } else {
      if (this.storageForm.value.liberarDesde == '' || this.storageForm.value.liberarHasta == '' || this.regimenSelected == null || this.regimenSelected == '0'
        || this.agentSelected == null || this.agentSelected == '' || this.storageForm.value.fechaEntrega == '' || this.storageForm.value.liberarHasta == '') {
        this.toastr.info('Favor llenar todos los campos requeridos.');        
        return;
      }
    }
    this.currentStep++;
  }

  async onRegimenSelected(regimen) {
    if (regimen.target.value == "0") return;
    this.regimenSelected = regimen.target.value;

    await this.agentService.getAgentsByRegimen(this.regimenSelected).toPromise()
      .then((agents) => {        
        const agentList = agents.map((agent) => {
          return { id: agent.agentId, text: agent.nameAgents, ...agent }
        });
        if (this.type == "entrance") {
          this.agentList = agentList.filter(item => item.typeAgent === "PRO");
        } else {
          this.agentList = agentList.filter(item => item.typeAgent === "CLI");
        }
        this.chRef.detectChanges();
      })
      .catch((error) => {        
        switch (error.status) {
          case 400:
            this.toastr.info('No hay agentes para el régimen seleccionado. Favor intente otra opción.', 'Aviso.');
            break;
          case 401:
            this.toastr.info('No tiene los permisos para realizar esta acción.', 'Aviso.');
            break;
          case 404:
            this.invalidData = 'No tienes agentes disponibles para continuar con esta solicitud.';
            Swal.fire('Aviso.', this.invalidData, 'info');
            break;
          case 500:
            this.invalidData = 'No tienes agentes disponibles para continuar con esta solicitud.';
            Swal.fire('Aviso.', this.invalidData, 'info');
            break;
          case 403:
            this.toastr.info('No tiene los permisos para realizar esta acción.', 'Aviso.');
            break;
          case 0:
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
          case undefined:
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
          default:
            this.toastr.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
        }
        this.agentList = [];
        this.chRef.detectChanges();
      });

    if (this.type == 'entrance' && this.agentList.length > 0) {
      this.productService.getProductsByRegimen(this.regimenSelected).subscribe((products) => {        
        const response = products.map(item => {
          return {
            productoId: item.productId,
            productName: item.productDescription,
            fechaVencimiento: item.expirationDate
          }
        })
        this.productList = response;
        this.productListResult = response;        
        this.invalidData = undefined;
        this.chRef.detectChanges();
      }, (error) => {              
        this.productList = [];
        switch (error.status) {
          case 400:
            this.toastr.info('No hay agentes para el régimen seleccionado. Favor intente otra opción.', 'Aviso.');
            break;
          case 401:
            this.toastr.info('No tiene los permisos para realizar esta acción.', 'Aviso.');
            break;
          case 500:
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
          case 404:
            this.invalidData = 'No tienes productos disponibles para continuar con esta solicitud.';
            Swal.fire('Aviso.', this.invalidData, 'info');            
            break;
          case 403:
            this.toastr.info('No tiene los permisos para realizar esta acción.', 'Aviso.');
            break;
          case 0:
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
          case undefined:
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
          default:
            this.toastr.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
        }
        this.chRef.detectChanges();
      });
    } else if (this.agentList.length > 0) {
      this.productService.getStockssByRegimen(this.regimenSelected).subscribe((products) => {
        this.productList = products;
        this.invalidData = undefined;
        this.productListResult = products;
        this.chRef.detectChanges();
      }, (error) => {        
        this.productList = [];
        switch (error.status) {
          case 400:
            this.toastr.info('No hay agentes para el régimen seleccionado. Favor intente otra opción.', 'Aviso.');
            break;
          case 401:
            this.toastr.info('No tiene los permisos para realizar esta acción.', 'Aviso.');
            break;
          case 404:
            this.invalidData = 'No tienes productos en stock disponibles para continuar con esta solicitud.';
            Swal.fire('Aviso.', this.invalidData, 'info');              
            break;
          case 500:
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
          case 403:
            this.toastr.info('No tiene los permisos para realizar esta acción.', 'Aviso.');
            break;
          case 0:
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
          case undefined:
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
          default:
            this.toastr.error('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
            break;
        }
        this.chRef.detectChanges();
      });
    }
  }

  searchProduct() {
    this.productListResult = this.productList.filter((product) => {
      return product.productName.toLowerCase().includes(this.productSearch.toLowerCase());
    });
    this.chRef.detectChanges();
  }

  selectProducts() {
    this.clearProductInputs()
    this.rerender();
  }

  clearProductInputs() {
    this.elementRef.nativeElement.querySelectorAll('.qtyInput').forEach((input) => {
      $(input).val('')
    })
  }

  setProductQty(event, product) {
    try {
      let qty = parseInt(event.target.value);
      if (qty > product.cantidadDisponible) {
        Swal.fire('Debe indicar una cantidad menor o igual a ' + product.cantidadDisponible, 'Validación', 'warning');
        return;
      }
      if (qty) {
        this.productListSelected[product.productoId] = { ...product, qty };
      } else {
        delete this.productListSelected[product.productoId];
      }

    } catch (e) {
      delete this.productListSelected[product.productoId];
    }

    this.rerender();
  }

  showSuccess() {
    this.toastr.success('Solicitud Almacenada satisfactoriamente:!', 'Confirmación.');

    if (this.type == "entrance") {
      this.router.navigate(['/storeage'])
    } else {
      this.router.navigate(['/exitstoreage'])
    }


  }

  showError(errortext) {
    this.toastr.error(errortext, 'Error en solicitud.');
  }

  selectedProducts(products) {
    this.productListSelected = products.value;
  }

  selectAgent(agent) {
    this.agentSelected = agent;
  }

  saveStorage() {
    if (Object.keys(this.productListSelected).length == 0) {
      //alert("Debe seleccionar al menos un producto");
      Swal.fire('Debe seleccionar al menos un producto', 'validación', 'warning');
      return;
    }

    Swal.fire({
      title: '',
      text: '¿Estás seguro en proceder con la solicitud y la información completada?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.value) {
        if (this.type == "entrance") {
          this.receptionModel = new ReceptionReferenceModel();
          // this.receptionModel.detail=[];
          this.receptionModel.Products = [];

          //saving wis
          this.receptionModel.NoReference = this.storageForm.value.referenceNumber;
          this.receptionModel.SelfReadable = 'S';
          this.receptionModel.AgentType = this.agentSelected.data[0].typeAgent;
          this.receptionModel.TruckPlate = this.regimenSelected;
          this.receptionModel.DriveName = this.storageForm.value.memo;
          this.receptionModel.DriversLicense = this.regimenSelected;
          this.receptionModel.Regime = this.regimenSelected;//mardomcompanyid?
          this.receptionModel.Commentary = this.storageForm.value.memo;
          this.receptionModel.AgentCode = this.agentSelected.value;
          this.receptionModel.Delivery = this.storageForm.value.fechaEntrega;

          for (let i in this.productListSelected) {
            this.receptionDetail = new ReceptionDetailModel();
            this.receptionDetail.Quantity = this.productListSelected[i].qty;
            this.receptionDetail.ProductId = this.productListSelected[i].productoId;
            this.receptionDetail.Lot = this.productListSelected[i].lote;
            this.receptionModel.Products.push(this.receptionDetail);
          }
          this.receptionreferenceService.sentReferenceWis(this.receptionModel).subscribe(res => {         
            this.payload$.subscribe(res => {
              let tokenData = jwt_decode(res.authToken)
              this.logService.postWithPayload(<LogDto>{
                ActionId : Action.CrearEntradaAlmacen,
                ScreenId : Screen.DespachoAlmacen,
                Json : JSON.stringify(this.receptionModel),
                UserName: res.user.fullname,
                Email: res.user.email,
                ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
                RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
              },
              res.authToken);
            })
            this.showSuccess();
          }, (error) => {
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
          })


        } else {
          this.orderEntry = new OrderModel();
          this.orderEntry.detail = [];
          this.orderEntry.Products = [];

          this.orderEntry.Regime = this.regimenSelected;
          this.orderEntry.ReleaseFrom = this.storageForm.value.liberarDesde;
          this.orderEntry.ReleaseUp = this.storageForm.value.liberarHasta;
          this.orderEntry.Delivery = this.storageForm.value.fechaEntrega;
          this.orderEntry.AgentCode = this.agentSelected.value;
          this.orderEntry.AskedNumber = this.agentSelected.value;

          for (let i in this.productListSelected) {
            this.orderDetail = new OrderDetailModel();
            this.orderDetail.Quantity = this.productListSelected[i].qty;
            this.orderDetail.ProductId = this.productListSelected[i].productoId;
            this.orderDetail.Lot = this.productListSelected[i].lote;
            this.orderEntry.Products.push(this.orderDetail);
          }
          this.orderService.sentOrderWis(this.orderEntry).subscribe(res => {
            this.payload$.subscribe(res => {
              let tokenData = jwt_decode(res.authToken)
              this.logService.postWithPayload(<LogDto>{
                ActionId : Action.CrearDespachoAlmacen,
                ScreenId : Screen.DespachoAlmacen,
                Json : JSON.stringify(this.orderEntry),
                UserName: res.user.fullname,
                Email: res.user.email,
                ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
                RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
              },
              res.authToken);
            })
            this.showSuccess();                    
          }, (error) => {
            this.showError('Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com');
          })
        }
      } else {
        return;
      }
    })
  }

  ngOnInit() {
    this.payload$ = this.store.pipe(select(selectAuthState));
    this.storageForm = this.fb.group({
      liberarDesde: '',
      liberarHasta: '',
      fechaEntrega: '',
      memo: '',
      referenceNumber: ''
    });

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      ordering: false
    }

    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.VerDetalleDespachoEntradaAlmacen,
        ScreenId : Screen.DespachoAlmacen,
        Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    })
  }

  rerender(): void {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();

        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
        this.chRef.detectChanges();
      });
    } else {
      this.dtTrigger.next();
    }

  }

}
