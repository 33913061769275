import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Action } from '../../../../models/enums/action.enum';
import { Screen } from '../../../../models/enums/screen.enum';
import { invoiceResult } from '../../../../models/response-result.model';
import { InvoiceService } from '../../../../services/invoice/invoice.service';
import * as jwt_decode from 'jwt-decode';
import { LogService } from '../../../../services/log/log.service';
import { LogDto } from '../../../../models/dto/log.dto';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
import { selectAllPermissions } from '../../../../core/auth';
import { PermissionWeb } from '../../../../core/auth/_models/permission.web.model';
import { selectAuthState } from '../../../../core/auth/_selectors/auth.selectors';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  constructor(private invoiceService: InvoiceService,
    private logService: LogService,
    private chRef: ChangeDetectorRef,
    private store: Store<AppState>) { }

  canAttachDocument: Boolean;
  canSeeAttachedDocuments: Boolean;
  canPrintInvoice: Boolean;
  @Input() isView: Boolean = true;

  permissions: PermissionWeb[];
  invoices: invoiceResult<any> = null;
  invoice: any;  
  page: number = 1;
  invoiceNumberField: string = ''

  currentPage: number = 1;
  pageSize: number = 5;
  start: number = 0;
  end: number = this.pageSize;

  payload$: any;

  ngOnInit() {
    this.payload$ =this.store.pipe(select(selectAuthState));

    this.getPendingInvoices();
    this.store.select(selectAllPermissions).subscribe(
      res => {
        this.permissions = res;
        this.checkPermissions();
      }
    )
  }

  checkPermissions(){    
    this.canAttachDocument = this.permissions.findIndex(item => item.name === 'canAttachDocument') > -1;
    this.canSeeAttachedDocuments = this.permissions.findIndex(item => item.name === 'canSeeAttachedDocuments') > -1;
    this.canPrintInvoice = this.permissions.findIndex(item => item.name === 'canPrintInvoice') > -1;
  }

  nextPage() {
    this.start += this.pageSize;
    this.end += this.pageSize;
    this.currentPage++;
  }

  previousPage() {
    this.start -= this.pageSize;
    this.end -= this.pageSize;
    this.currentPage--;
  }

  onPageChange(item: number) {
    this.page = item
    this.getPendingInvoices();
    this.chRef.detectChanges();
  }

  getPendingInvoices() {
    this.invoiceService
      .getPendingInvoices(this.page, this.invoiceNumberField)
      .subscribe((data) => {
        this.invoices = data;
        this.chRef.detectChanges();
      },
        err => {
          if (err.status == 400) {
            this.invoices = { results: <any>[], total: 0 };
            this.chRef.detectChanges();
          }
        });
  }

  searchInvoice() {
    this.page = 1;
    this.getPendingInvoices()
  }

  downloadInvoice(invoice) {
    this.invoice = invoice;
    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId: Action.ImprimirFactura,
        ScreenId: Screen.AccesoConsultaFacturas,
        Json: JSON.stringify(invoice),
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName: tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
        res.authToken);
    })
    if (invoice.isFromDynamic) {
      this.invoiceService.downloadDynamicBill(this.invoice.number).subscribe(response => {

        const url = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.setAttribute('target', '_blank');
        fileLink.href = url;
        fileLink.click();
      })
    } else if (invoice.company == 'FreightForward') {
      this.invoiceService.downloadBillFreightForward(this.invoice.number).subscribe(response => {

        const url = window.URL.createObjectURL(response);
        //window.open(url);    

        var fileLink = document.createElement('a');
        fileLink.setAttribute('target', '_blank');
        fileLink.href = url;
        fileLink.click();
        //window.open(url,sfile.file_type);    

      }), error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
    } else {
      this.invoiceService.downloadBill(this.invoice.id).subscribe(response => {

        const url = window.URL.createObjectURL(response);
        var fileLink = document.createElement('a');
        fileLink.setAttribute('target', '_blank');
        fileLink.href = url;
        fileLink.click();
        //window.open(url);    
      }), error => console.log('Error downloading the file'),
        () => console.info('File downloaded successfully');
    }
  }

}
