import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AzurefileService {

  uri = serviceConfig.filesUrl;
  headers: HttpHeaders;
  userId;

  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {  
      var decoded = jwt_decode(result.authToken); 
      //real userid.
      this.userId=decoded['https://mardom.com/clientId'];
      
      this.headers =new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('accept','*/*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
    });
  }

  downloadFile(id:number): Observable<any>{
    let url= this.uri + serviceConfig.endPoints.azureDownload;
		return this.http.get(`${url}/${id}`, { headers: this.headers, responseType:'blob'});
  }

  uploadFiles(files){
    let url = serviceConfig.filesUrl+serviceConfig.endPoints.azureUpload;
    let formData = new FormData();
    for(let i in files){
      let file = files[i];
      if( file instanceof File){
        formData.append('files[]', file, file.name);
      }
    }     
    return this.http.post(url,formData, { headers:this.headers } );
  }
}
