// Actions
import { AuthActions, AuthActionTypes } from '../_actions/auth.actions';
// Models
import { User } from '../_models/user.model';
import { logging } from 'protractor';

export interface AuthState {
  loggedIn: boolean;
  authToken: string;
  idToken: string;
  user: User;
  loggingIn: boolean;
  isUserLoaded: boolean;
}

export const initialAuthState: AuthState = {
  loggedIn: false,
  authToken: undefined,
  idToken: undefined,
  user: undefined,
  loggingIn: false,
  isUserLoaded: false
};

export function authReducer(
  state = initialAuthState,
  action: AuthActions
): AuthState {
  switch (action.type) {
    case AuthActionTypes.BeginLogin: {
      return {
        loggedIn: false,
        authToken: null,
        idToken: undefined,
        user: undefined,
        loggingIn: true,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.RefreshTokens: {
      return {
        ...state,
        authToken: action.payload.accessToken,
        idToken: action.payload.idToken
      };
    }

    case AuthActionTypes.Login: {
      const _token: string = action.payload.accessToken;
      return {
        loggedIn: true,
        authToken: _token,
        idToken: action.payload.idToken,
        user: undefined,
        loggingIn: false,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.Register: {
      const _token: string = action.payload.authToken;
      return {
        loggedIn: true,
        authToken: _token,
        idToken: null,
        user: undefined,
        loggingIn: false,
        isUserLoaded: false
      };
    }

    case AuthActionTypes.Logout:
      return initialAuthState;

    case AuthActionTypes.UserLoaded: {
      const _user: User = action.payload.user;
      return {
        ...state,
        user: _user,
        isUserLoaded: true
      };
    }

    default:
      return state;
  }
}
