export class ImportExport{
    bookingId: number;
    shipping_port: number;
    shipping_date: number;
    landing_port: string;
    reference: string;
    ship_id: number;
    quotation_number: string;
    container_pickupDate: string;
    cargo_pickupDate: string;
    shipper_id: number;
    consignee_id: number;
    notified_id: number;
    forwarder_id: number;
    destination_deliveryId: number;
    origen_pickupid: number;
    origen_custom: boolean;
    destination_custom: boolean;
    cargo1_id: number;
    cargo2_id: number;
    hazmat_id: number;
    pago_id: number;
    insured_cargo: boolean;
    user_id: string;
    created_date: string;
    assigned_user: string;
    Status: string;
    line_id: string;
    mardom_company_id: number;
    transport_id: number;
    customs_id: number;
    special_permission: boolean;
    payment_terms: number;
    payed_by: number;
    request_service: number;
    cargo_type: number;
    refrigerated_cargo: boolean;
    hazmat: boolean;
    fumigated: boolean;
    export_type: number;
    container_qty: number;

    //
    ventilacion: number;
    temperatura:number;
    temp_unit:string;
    cargo_destination: string;
    humedad:number;
    hazmat_qtycontainer:number;
    hazmatnumero_un:string;
    hazmatcodigo_imo:string;
    hazmatcomp_peligroso:string;
    hazmatpeso_neto:number;
    hazmatpeso_grueso:number;
    hazmatflashpoint:string;
    codigo_empaque:string;
    nombre_emergencia:string;
    telefono_emergencia:string;    
    carbonDioxideLevel:number
    oxygenLevel:number
    ventilationUnit:number
    commodity:number

}