import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { exitColumns } from '../storage/StorageColumns';
import { OrderService } from '../../services/warehouse/order.service';
import { ReceptionreferenceService } from '../../services/warehouse/receptionreference.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { select, Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import { AppState } from '../../core/reducers/index';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { LogService } from '../../services/log/log.service';
import { LogDto } from '../../models/dto/log.dto';
import { Action } from '../../models/enums/action.enum';
import { Screen } from '../../models/enums/screen.enum';
import { ResponseStorageResult } from '../../models/response-result.model';

@Component({
  selector: 'kt-exitstorage',
  templateUrl: './exitstorage.component.html',
  styleUrls: ['./exitstorage.component.scss']
})
export class ExitstorageComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  storeages: ResponseStorageResult<any[]> = new ResponseStorageResult<any[]>();
  detail = [];
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  searchForm: FormGroup;
  title: string;
  private sub: any;
  adminFilter: boolean = false;
  routeToNew: string;
  page: number = 1;
  loading: boolean = false;
  notRows: boolean;
  payload$: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private orderService: OrderService,
    private chRef: ChangeDetectorRef,
    private store: Store<AppState>,
    private toastr: ToastrService,
    private logService: LogService,
    private receptionreferenceService: ReceptionreferenceService) {
  
  }

  showError(message) {
    this.toastr.error(message, 'Error.');
  }

  ngOnInit() {
    this.checkUserRole();
    this.payload$ = this.store.pipe(select(selectAuthState));

    this.payload$.subscribe(res => {
      let tokenData = jwt_decode(res.authToken)
      this.logService.postWithPayload(<LogDto>{
        ActionId : Action.AccederPantalla,
        ScreenId : Screen.DespachoAlmacen,
        Json : '',
        UserName: res.user.fullname,
        Email: res.user.email,
        ClientId: tokenData['https://mardom.com/clientId'] == '' ? null : tokenData['https://mardom.com/clientId'],
        RoleName:tokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'][0]
      },
      res.authToken);
    })    
    this.searchForm = this.fb.group({
      statusTypeFilter: [0],
      dateFrom: '',
      dateTo: '',
      requestId: ''
    });

    this.routeToNew = '/storageDetail/exit';
    this.title = 'Despachos de Almacén';

    this.searchStorage(1, 10);
  }

  checkUserRole() {
    this.store.select(selectAuthState).subscribe(result => {
      var decoded = jwt_decode(result.authToken);
      var currentRoles =
        decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      var internal = currentRoles.indexOf('Client');
      if (!(internal > -1)) {
        this.adminFilter = true;
      } else {
        this.adminFilter = false;
      }
    });
  }
  onPageChange(item: number) {
    this.page = item;
    this.searchStorage(item, 10);
  }
  get pageTotal() {
    return Math.ceil(this.storeages ? this.storeages.Total / 10 : 0);
  }

  get pages(): number[] {
    let pages = [];
    for (let i = 0; i < this.pageTotal; i++) {
      pages.push(i + 1);
    }
    return pages;
  }

  changeDate() {
    let fromDate = new Date(this.searchForm.value.dateFrom);
    let toDate = new Date(this.searchForm.value.dateTo);
    if (fromDate > toDate) this.searchForm.get('dateTo').setValue('');
  }
  clearFields() {
    this.searchForm = this.fb.group({
      statusTypeFilter: [0],
      dateFrom: '',
      dateTo: '',
      requestId: ''
    });
    this.searchStorage(1, 10);
    this.rerender();
  }
  searchStorage(pageNumber: number, pageSize: number) {
    var orderId =
      this.searchForm.value.requestId == ''
        ? 0
        : this.searchForm.value.requestId;
    var dateFrom =
      this.searchForm.value.dateFrom == ''
        ? null
        : this.searchForm.value.dateFrom;
    var dateTo =
      this.searchForm.value.dateTo == '' ? null : this.searchForm.value.dateTo;
    var statusTypeFilter =
      this.searchForm.value.statusTypeFilter == ''
        ? null
        : this.searchForm.value.statusTypeFilter;

    this.orderService
      .getOrderList(
        orderId,
        dateFrom,
        dateTo,
        statusTypeFilter,
        0,
        pageNumber,
        pageSize
      )
      .subscribe(
        data => {
          let formatedData = data['response'].map(item => {
            return {
              ...item,
              showDetail: false
            };
          });
          this.storeages.Response = formatedData;
          this.storeages.Total = data['total'];
          this.notRows = data['total'] == '0';
          this.rerender();
        },
        error => {
          this.storeages.Response = [];
          this.notRows = true;
          this.rerender();
          switch (error.status) {
            case 400:
              this.toastr.info(
                'No tiene despachos de almacén para consultar.',
                'Aviso.'
              );
              break;
            case 401:
              this.toastr.info(
                'No tiene los permisos para realizar esta acción.',
                'Aviso.'
              );
              break;
            case 500:
              this.showError(
                'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
              );
              break;
            case 403:
              this.toastr.info(
                'No tiene los permisos para realizar esta acción.',
                'Aviso.'
              );
              break;
            case 0:
              this.showError(
                'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
              );
              break;
            case undefined:
              this.showError(
                'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
              );
              break;
            default:
              this.toastr.error(
                'Ha ocurrido un error, favor intente de nuevo. Si el error persiste , comunicarse al correo mardomgo@mardom.com'
              );
              break;
          }
        }
      );

    this.chRef.detectChanges();
  }

  rerender(): void {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {    
        dtInstance.destroy(); 
        this.dtTrigger.next();
        this.chRef.detectChanges();
      });
    } else {
      this.dtTrigger.next();
      this.chRef.detectChanges();
    }
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
