import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";

import serviceConfig from './service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../core/auth/_selectors/auth.selectors';
import { AppState } from '../core/reducers/index';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TrackAndTraceService {

  uri = serviceConfig.trackAndTraceBaseUrl ;
  headers: HttpHeaders;
  clientId:'';

  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {  
      var decoded = jwt_decode(result.authToken); 
      //real clientId.
      this.clientId=decoded['https://mardom.com/clientCode'];
      
      this.headers =new HttpHeaders()
                    .append('Authorization', "Bearer "+result.authToken)  
                    .append('Content-Type', 'application/json')
                    .append('accept','*/*')
                    .append('Allow', '*')
                    .append('Access-Control-Allow-Origin', '*')
                    .append('Access-Control-Allow-Methods', 'POST, GET, PUT, DELETE, OPTIONS')
                    .append('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent')
                    .append('accept','text/plain');
    });
  }

  getResultBy(endpoint:string, id: string):Observable<any> {
    let url= this.uri + serviceConfig.endPoints[endpoint];    
    return this
      .http
      .get<any>(`${url}/${id}`,
       { headers: this.headers });
  }

  getLastBLs(pageNumber : number, pageSize:number):Observable<any> {
    let url= this.uri + serviceConfig.endPoints.trackingLasts;    
    return this
      .http
      .get<any>(`${url}/?pageNumber=${pageNumber}&pageSize=${pageSize}`,
       { headers: this.headers });
  }

  
}
