export class ReceptionDetailModel{
    //MardomGoWeb
    detailId:number;
    referenceId:number;
    productId :string;///askednumber
    product_description:string;
    reference_qty :number;

    ProductId:string;
    Quantity:string;
    Lot:string;
}