import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse  } from "@angular/common/http";

import serviceConfig from '../service-config';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers/index';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class TrackblsService {

  uri = serviceConfig.baseUrl + serviceConfig.endPoints.trackBL;
  headers: HttpHeaders;
  userId:'';
  altHeaders: HttpHeaders;

  constructor(private http:HttpClient, private store: Store<AppState>) { 
    this.store.select(selectAuthState).subscribe((result) => {  
      var decoded = jwt_decode(result.authToken); 
      //real userid.
      this.userId=decoded['https://mardom.com/clientId'];
      
      this.altHeaders = new HttpHeaders()
                          .append('Authorization', "Bearer "+result.authToken)  
                          .append('Content-Type', 'application/json')
                          .append('accept','*/*')
                          .append('accept','text/plain');
      this.headers =new HttpHeaders()
                          .append('Authorization', "Bearer "+result.authToken)  
                          .append("Access-Control-Allow-Origin", "*")
                          .append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
                          .append('accept','*/*')
                          .append('accept','text/plain');
    });
  }

  getBls(maxRows:number):Observable<any> {
    return this
      .http
      .get<any>(`${this.uri}?userId=${this. userId}&max=${maxRows}`,
       { headers: this.altHeaders });

  }

}
