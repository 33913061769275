import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { PanelComponent } from './components/panel/panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { InvoiceService } from '../../services/invoice/invoice.service';
import { LogService } from '../../services/log/log.service';



@NgModule({
  declarations: [InvoicesComponent, PanelComponent],
  entryComponents: [InvoicesComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    DataTablesModule,
    RouterModule
  ],
  providers:[
    InvoiceService,
    LogService
  ]
})
export class DashboardModule { }
