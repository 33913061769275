import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectAuthState } from '../../core/auth/_selectors/auth.selectors';
import { AppState } from '../../core/reducers';
import serviceConfig from '../service-config';
import { environment } from '../../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { IEquipment } from '../../models/transport-request/equipment';
import { IFreightLocation } from '../../models/transport-request/freight-location';
import { IEquipmentType } from '../../models/transport-request/equipment-type';

@Injectable({
  providedIn: 'root'
})
export class TransportRequestService {
  headers: HttpHeaders;
  baseUrl: string;  

  constructor(private http: HttpClient, private store: Store<AppState>) {
    this.store.select(selectAuthState).subscribe((result) => {
      this.headers = new HttpHeaders()
        .append('Authorization', "Bearer " + result.authToken)
        .append('Content-Type', 'application/json')
        .append('accept', '*/*')
        .append("Access-Control-Allow-Origin", "*")
        .append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
        .append('accept', 'text/plain');
    })
    this.baseUrl = serviceConfig.altBaseUrl;
  }

  getEquipments(): Observable<IEquipment[]> {
    return this.http.get<IEquipment[]>(this.baseUrl+serviceConfig.endPoints.tmsEquipment, {headers: this.headers});
  }

  getEquipmentTypes(): Observable<IEquipmentType[]> {
    return this.http.get<IEquipmentType[]>(this.baseUrl+serviceConfig.endPoints.tmsEquipmentType, {headers: this.headers});
  }

  getFreightLocations(): Observable<IFreightLocation[]> {
    return this.http.get<IFreightLocation[]>(this.baseUrl+serviceConfig.endPoints.tmsLocations, {headers: this.headers});
  }

}
