import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'kt-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent implements OnInit {

  @Input() steps: any[];
  @Input() actualStep: number = 0;
  @Input() subStatus: string;
  @Input() subStatusDate: string;

  constructor() { 
   
  }

  ngOnInit() {
    
  }

}
