export class OrderDetailModel{
    //MardomGoWeb
    detailId:number;
    orderId:number;
    productId :string;///askednumber
    product_description:string;
    order_qty :number;

    //wis fields
    ProductId:string;
    Quantity:string;
    Lot:string;
   
}