export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboards',
          root: true,
          alignment: 'left',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD'
        },
        {
          title: 'Components',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Google Material',
              bullet: 'dot',
              icon: 'flaticon-interface-7',
              submenu: [
                {
                  title: 'Form Controls',
                  bullet: 'dot',
                  submenu: [
                    {
                      title: 'Auto Complete',
                      page: '/material/form-controls/autocomplete',
                      permission: 'accessToECommerceModule'
                    }
                  ]
                }
              ]
            }
          ]
        }
        // tslint:disable-next-line:indent
      ]
    },
    aside: {
      self: {},
      items: [
        {
          title: 'Dashboard',
          root: true,
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard',
          translate: 'MENU.DASHBOARD',
          bullet: 'dot'
        },
        {
          title: 'Layout Builder',
          root: true,
          icon: 'flaticon2-expand',
          page: '/builder'
        },
        { section: 'Components' },
        {
          title: 'Google Material',
          root: true,
          bullet: 'dot',
          icon: 'flaticon2-browser-2',
          submenu: [
            {
              title: 'Form Controls',
              bullet: 'dot',
              submenu: [
                {
                  title: 'Auto Complete',
                  page: '/material/form-controls/autocomplete',
                  permission: 'accessToECommerceModule'
                }
              ]
            }
          ]
        }
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
