// Angular
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatProgressSpinnerModule } from '@angular/material';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LOCALE_ID } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Select2Module } from 'ng2-select2';
import { DataTablesModule } from 'angular-datatables';
import { NgxMaskModule } from 'ngx-mask';
import { NgFileDragDropModule } from 'ng-file-drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';

// Angular in memory
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Perfect Scroll bar
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg';
// Env
import { environment } from '../environments/environment';
// Hammer JS
import 'hammerjs';
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// State
import { metaReducers, reducers } from './core/reducers';
// Copmponents
import { AppComponent } from './app.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ThemeModule } from './views/theme/theme.module';
// Partials
import { PartialsModule } from './views/partials/partials.module';
// Layout Services
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService
} from './core/_base/layout';

// CRUD
import {
  HttpUtilsService,
  LayoutUtilsService,
  TypesUtilsService
} from './core/_base/crud';
// Config
import { LayoutConfig } from './core/_config/layout.config';
// Highlight JS
import { HIGHLIGHT_OPTIONS, HighlightLanguage } from 'ngx-highlightjs';
import * as typescript from 'highlight.js/lib/languages/typescript';
import * as scss from 'highlight.js/lib/languages/scss';
import * as xml from 'highlight.js/lib/languages/xml';
import * as json from 'highlight.js/lib/languages/json';

import { LoginComponent } from './views/session/login/login.component';
import { MdheaderComponent } from './views/layout/mdheader/mdheader.component';
import { MdfooterComponent } from './views/layout/mdfooter/mdfooter.component';
import { MdmaincontainerComponent } from './views/layout/mdmaincontainer/mdmaincontainer.component';
import { LocateCargoComponent } from './views/locate-cargo/locate-cargo.component';
import { UserProfileComponent } from './views/user-profile/user-profile.component';
import { UserInfoComponent } from './views/user-info/user-info.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthcallbackComponent } from './views/session/authcallback/authcallback.component';
import { BlankpageComponent } from './views/blankpage/blankpage.component';
import { StorageComponent } from './views/storage/storage.component';
import { QuotationComponent } from './views/quotation/quotation.component';
import { EstimateComponent } from './views/estimate/estimate.component';
import { VoucherComponent } from './views/voucher/voucher.component';
import { RequestService } from './services/booking/request.service';
import { InvoiceService } from './services/invoice/invoice.service';
import { ResponsibleService } from './services/responsiblemanagement/responsible.service'
import { RatesService } from './services/miscelaneos/rates.service'
import { BookingIEService } from './services/booking/booking-ie.service';
import { CustomsService } from './services/booking/customs.service';
import { TransportService } from './services/booking/transport.service';
import { ContactService } from './services/booking/contact.service';
import { DatePipe } from '@angular/common';
import { StorageDetailComponent } from './views/storage-detail/storage-detail.component';
import { LocateResultComponent } from './views/locate-result/locate-result.component';
import { LoadingInterceptor } from './services/LoadingInterceptor';
import { MessageComponent } from './views/message/message.component';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { StatusIndicatorComponent } from './views/locate-cargo/status-indicator/status-indicator.component'
import { ClientService } from './services/accountmanagement/client.service';
import { ExitstorageComponent } from './views/exitstorage/exitstorage.component';
import { SalesexecutiveService } from './services/salesexecutive/salesexecutive.service';
import { LogService } from './services/log/log.service';
import { ServiceRequestModule } from './components/service-request/service-request.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './components/shared/shared.module';


// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300
};


export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

export function hljsLanguages(): HighlightLanguage[] {
  return [
    { name: 'typescript', func: typescript },
    { name: 'scss', func: scss },
    { name: 'xml', func: xml },
    { name: 'json', func: json }
  ];
}

@NgModule({
  declarations: [AppComponent,
    LoginComponent,
    MdheaderComponent,
    MdfooterComponent,
    MdmaincontainerComponent,    
    LocateCargoComponent,
    UserProfileComponent,
    UserInfoComponent,
    RegisterComponent,
    AuthcallbackComponent,    
    BlankpageComponent, StorageComponent, QuotationComponent, EstimateComponent, VoucherComponent, StorageDetailComponent, LocateResultComponent, MessageComponent, StatusIndicatorComponent, ExitstorageComponent],
  imports: [
    BrowserAnimationsModule,
    ServiceRequestModule,
    SharedModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    PartialsModule,
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreDevtoolsModule.instrument(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    ThemeModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgbModule,
    Select2Module,
    DataTablesModule,
    NgxMaskModule.forRoot(),
    NgFileDragDropModule,
    DashboardModule
  ],
  exports: [],
  providers: [
    DatePipe,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    DataTableService,
    SplashScreenService,
    RequestService,
    ResponsibleService,
    RatesService,
    BookingIEService,
    CustomsService,
    TransportService,
    InvoiceService,
    ContactService,
    ClientService,
    LogService,
    SalesexecutiveService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: { languages: hljsLanguages }
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: "es-DO" },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
