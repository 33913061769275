import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ServiceRequestState } from "../state/service-request.state";

const getServiceRequestState = createFeatureSelector<ServiceRequestState>('serviceRequest');

export const getTMSEquipments = createSelector(
    getServiceRequestState,
    state => state.equipments
);

export const getTMSEquipmentTypes = createSelector(
    getServiceRequestState,
    state => state.equipmentTypes
);

export const getTMSFreightLocations = createSelector(
    getServiceRequestState,
    state => state.freghtLocations
);

export const getSearchForm = createSelector(
    getServiceRequestState,
    state => state.searchForm
);